import React from 'react';
import './KreditzProvidersModal.css'
import { useState } from "react";

const attachData = { update_icon: "", file_data: "" }
const KreditzProvidersModal = (props) => {
  const [fileData, setFileData] = useState(attachData);

  const handleAttachmentChange = (event, index) => {
    let value = event.target.files[0];
    let file_name = value.name
    if (value) {
      setFileData(prevState => ({
        ...prevState,
        update_icon: file_name
      }))

      let reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = function () {
        setFileData(prevState => ({
          ...prevState,
          file_data: reader.result
        }))
      };
      reader.onerror = function (error) {
      };
    }
  }

  const submitHandle = async (e) => {
    e.preventDefault();
    const data = {
      id: props?.id,
      file_name: fileData?.file_data
    };
    props.handleUpdateIcon(data)
  }
  return (
    <div className='EditPopup'>
      <div className='EditPopup_inner'>
        <form onSubmit={submitHandle}>
          <h3>Update Bank Icon</h3>
          <div className="upload-bank-icon">
            <div className="icon-box">
              <input type="file" accept="image/*" onChange={(e) => handleAttachmentChange(e, 0)} required />
            </div>
          </div>
          <div className="modal-btns">
            <button className='closeDelete-update' style={{ marginLeft: '0px' }} onClick={props.closeDelete}>Cancel</button>
            <button className='confirm-update' type='submit' >Update</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default KreditzProvidersModal;