import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import "./Healthcheck.scss";
import Dropdown from "./misc/Dropdown";
import PaginationBar from "./misc/PaginationBar";
import SearchField from './misc/Searchfield';
import StatusTable from "./misc/StatusTable";
import { getOrganizationProvider } from '../../Redux/Actions/OrganizationProvider';
import { getHealthcheck } from '../../Redux/Actions/Healthcheck';
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';
import { useHistory } from "react-router-dom";
import { clearFilterHandler } from "../../Utils/reloadPage";

const Healthcheck = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [services, setServices] = useState([]);
  const [countries, setCountries] = useState([]);
  const [status, setStatus] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [selectedMarket, setSelectedMarket] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const isMountedRef = useRef(false);
  const [selectedStatusDropdown, setSelectedStatusDropdown] = useState('');
  const [selectedTypeDropdown, setSelectedTypeDropdown] = useState('');
  const capitalizeEachWord = (string) => { return string?.replace(/_/g, ' ')?.toLowerCase()?.split(' ')?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(' '); };
  const [defaultMarket, setDefaultMarket] = useState("");
  const [defaultProvider, setDefaultProvider] = useState("");
  const isDataEmpty = useCallback((data) => !data || data.length === 0, []);
  const saveFiltersToLocalStorage = (filters) => {
    const dataWithTimestamp = { ...filters, timestamp: new Date().getTime() };
    localStorage.setItem('healthcheckFilters', JSON.stringify(dataWithTimestamp));
  };
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const setDefaultValues = async () => {
      const orgProviderRes = await dispatch(getOrganizationProvider());
      if (orgProviderRes.status === true) {
        const capitalizedServices = orgProviderRes.data.service_list.map(service => ({
          ...service,
          name: capitalizeEachWord(service.name)
        }));
        setServices(capitalizedServices);

        const capitalizedCountries = orgProviderRes.data.countries_list.map(country => ({
          ...country,
          name: capitalizeEachWord(country.name)
        }));
        setCountries(capitalizedCountries);

        const defaultMarketId = orgProviderRes.data.countries_list.find(country => country.name.toLowerCase() === 'sweden')?.id || "";
        const defaultProviderId = capitalizedServices?.find(service => service?.name?.toLowerCase().includes('enable banking'))?.id || "";

        const savedFilters = JSON.parse(localStorage.getItem('healthcheckFilters'));
        if (savedFilters && new Date().getTime() - savedFilters.timestamp < 30000) {
          setSearchKey(savedFilters.searchTerm || "");
          setSelectedMarket(savedFilters.selectedMarket !== "" ? savedFilters.selectedMarket : defaultMarketId);
          setSelectedProvider(savedFilters.selectedProvider !== "" ? savedFilters.selectedProvider : defaultProviderId);
        } else {
          setDefaultMarket(defaultMarketId);
          setDefaultProvider(defaultProviderId);
        }
      }
    };

    if (isMountedRef.current) {
      setDefaultValues();
    }
  }, [dispatch, isMountedRef]);

  const fetchHealthcheckData = useCallback(async (page, searchTerm, market, provider, status, type) => {
    dispatch(setLoaderSpinner(true));
    let missingValues = type === 'System' ? 'true' : type === 'Banks' ? 'exclude' : undefined;
    try {
      const healthcheckRes = await dispatch(getHealthcheck(page, searchTerm, market, provider, status, missingValues));

      if (healthcheckRes.status === 200) {
        if (isMountedRef.current) {
          setStatus(healthcheckRes.data);
          setCurrentPage(healthcheckRes.pagination.current_page);
          setTotalPages(healthcheckRes.pagination.total_pages);
        }
      } else if (healthcheckRes.status === 401) {
        history.push('/login');
      } else {
        console.error("Unexpected API response format:", healthcheckRes);
      }
    } catch (error) {
      console.error("Failed to fetch healthcheck data:", error);
    } finally {
      if (isMountedRef.current) {
        dispatch(setLoaderSpinner(false));
      }
    }
  }, [dispatch, history]);

  useEffect(() => {
    if (isMountedRef.current && services.length > 0 && countries.length > 0) {
      fetchHealthcheckData(currentPage, searchQuery, selectedMarket, selectedProvider, selectedStatusDropdown, selectedTypeDropdown);
    }
  }, [currentPage, searchQuery, selectedMarket, selectedProvider, selectedStatusDropdown, selectedTypeDropdown, services, countries, fetchHealthcheckData, isMountedRef]);

  const handleSearchChange = (event) => {
    setSearchKey(event.target.value);
  };
  const handleSearchSubmit = () => {
    const searchTerm = searchKey.trim();
    setSearchQuery(searchTerm);
    fetchHealthcheckData(1, searchTerm, selectedMarket, selectedProvider, selectedStatusDropdown, selectedTypeDropdown);
    saveFiltersToLocalStorage({
      searchTerm,
      selectedMarket,
      selectedProvider,
      selectedStatusDropdown,
      selectedTypeDropdown,
      currentPage: 1
    });
  };

  const handleSelectMarket = (value) => {
    const newMarket = value === "All markets" ? "" : value;
    setSelectedMarket(newMarket);
    fetchHealthcheckData(currentPage, searchQuery, newMarket, selectedProvider, selectedStatusDropdown, selectedTypeDropdown);
    saveFiltersToLocalStorage({
      searchTerm: searchQuery,
      selectedMarket: newMarket,
      selectedProvider,
      selectedStatusDropdown,
      selectedTypeDropdown,
      currentPage
    });
  };

  const handleSelectProvider = (value) => {
    const newProvider = value === "All services" ? "" : value;
    setSelectedProvider(newProvider);
    fetchHealthcheckData(currentPage, searchQuery, selectedMarket, newProvider, selectedStatusDropdown, selectedTypeDropdown);
    saveFiltersToLocalStorage({
      searchTerm: searchQuery,
      selectedMarket,
      selectedProvider: newProvider,
      selectedStatusDropdown,
      selectedTypeDropdown,
      currentPage
    });
  };

  const statusOptions = [
    { value: 'Ok', label: 'Ok' },
    { value: 'Error', label: 'Error' },
  ];

  const handleSelectStatusDropdown = (value) => {
    setSelectedStatusDropdown(value);
    fetchHealthcheckData(currentPage, searchQuery, selectedMarket, selectedProvider, value, selectedTypeDropdown);
    saveFiltersToLocalStorage({
      searchTerm: searchQuery,
      selectedMarket,
      selectedProvider,
      selectedStatusDropdown: value,
      selectedTypeDropdown,
      currentPage
    });
  };

  const typeOptions = [
    { value: 'Banks', label: 'Banks' },
    { value: 'System', label: 'System' },
  ];

  const handleSelectTypeDropdown = (value) => {
    if (value !== selectedTypeDropdown) {
      setSelectedTypeDropdown(value);
      fetchHealthcheckData(currentPage, searchQuery, selectedMarket, selectedProvider, selectedStatusDropdown, value);
      saveFiltersToLocalStorage({
        searchTerm: searchQuery,
        selectedMarket: selectedMarket,
        selectedProvider,
        selectedStatusDropdown,
        selectedTypeDropdown: value,
        currentPage
      });
    }
  };

  const filterStatuses = () => {
    let filtered = [...status];

    if (selectedMarket) {
      filtered = filtered.filter(s => s.country_id === parseInt(selectedMarket));
    }
    if (selectedProvider) {
      filtered = filtered.filter(s => s.service_id === parseInt(selectedProvider));
    }
    if (selectedStatusDropdown) {
      filtered = filtered.filter(s => s.status.toLowerCase() === selectedStatusDropdown.toLowerCase());
    }

    setFilteredData(filtered);
  };

  useEffect(() => {
    filterStatuses();
  }, [status, selectedMarket, selectedProvider, selectedStatusDropdown]);

  const tableData = filteredData.map(statusItem => {
    const serviceName = services.find(service => service.id === statusItem.service_id)?.name.replace(/_/g, " ") || "N/A";
    const country = countries.find(country => country.id === statusItem.country_id)?.name || "N/A";

    return {
      id: statusItem.id,
      type: statusItem.type,
      bankName: statusItem.bank_details?.bank_name,
      status: statusItem.status,
      lastCheck: statusItem.last_successful_check,
      errorMessage: statusItem.error_message,
      description: statusItem.description,
      service: statusItem.service_id,
      serviceName: serviceName,
      statustype: statusItem.bank_details?.type,
      identifier: statusItem.bank_details?.identifier,
      country: country,
      updatedAt: statusItem.updated_at
    };
  });

  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('healthcheckFilters'));
    if (savedFilters) {
      setSearchKey(savedFilters.searchTerm || "");
      setSelectedMarket(savedFilters.selectedMarket || defaultMarket);
      setSelectedProvider(savedFilters.selectedProvider || defaultProvider);
      setSelectedStatusDropdown(savedFilters.selectedStatusDropdown || "");
      setSelectedTypeDropdown(savedFilters.selectedTypeDropdown || "");
      setCurrentPage(savedFilters.currentPage || 1);
      fetchHealthcheckData(savedFilters.currentPage, savedFilters.searchTerm, savedFilters.selectedMarket, savedFilters.selectedProvider);
    }
  }, []);

  useEffect(() => {
    const loadFilters = () => {
      const savedFilters = JSON.parse(localStorage.getItem('healthcheckFilters'));
      if (savedFilters) {
        const currentTime = new Date().getTime();
        const thirtySeconds = 60 * 1000;
        if (currentTime - savedFilters.timestamp < thirtySeconds) {
          setSearchKey(savedFilters.searchTerm || "");
          setSearchQuery(savedFilters.searchTerm || "");
          setSelectedMarket(savedFilters.selectedMarket || defaultMarket);
          setSelectedProvider(savedFilters.selectedProvider || defaultProvider);
          setSelectedStatusDropdown(savedFilters.selectedStatusDropdown || "");
          setSelectedTypeDropdown(savedFilters.selectedTypeDropdown || "");
          setCurrentPage(savedFilters.currentPage || 1);
          fetchHealthcheckData(savedFilters.currentPage, savedFilters.searchTerm, savedFilters.selectedMarket, savedFilters.selectedProvider);
        } else {
          setSelectedMarket(defaultMarket);
          setSelectedProvider(defaultProvider);
          setSelectedStatusDropdown("");
          setSelectedTypeDropdown("");
          setCurrentPage(1);
          fetchHealthcheckData(1, "", defaultMarket, defaultProvider, "", "");
        }
      }
    };
    if (isMountedRef.current) {
      loadFilters();
    }
  }, [defaultMarket, defaultProvider, fetchHealthcheckData, isMountedRef]);

  const handlePageChange = (newPage) => {
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
      fetchHealthcheckData(newPage, searchQuery, selectedMarket, selectedProvider, selectedStatusDropdown, selectedTypeDropdown);
      saveFiltersToLocalStorage({
        searchTerm: searchQuery,
        selectedMarket,
        selectedProvider,
        selectedStatusDropdown,
        selectedTypeDropdown,
        currentPage: newPage
      });
    }
  };
  return (
    <div className="page-container healthcheck-container">
      <div className="ld-container">
        <h3 className="ld-header-healthcheck">Healthcheck</h3>
      </div>
      <div className="inner-page-container-healthcheck">
          <div className="dropdowns-container-healthcheck">
            <div className="searchfield">
              <SearchField
                searchData={searchKey}
                onChange={handleSearchChange}
                searchOrgSubmit={handleSearchSubmit}
                placeholder="Search by Bank Name"
              />
            </div>
          </div>
        <div className="controls-container-healthcheck">
          <div className="dropdowns-container-healthcheck">
            <Dropdown
              className="dropdown-healthcheck"
              id="drop1"
              options={countries.map((country) => ({
                value: country.id,
                label: country.name,
              }))}
              onSelect={handleSelectMarket}
              placeholder="All markets"
              selected={selectedMarket || ""}
              disabled={selectedTypeDropdown && selectedTypeDropdown.toLowerCase() === "system"}
              tooltipText={"Please deselect System to enable this dropdown"}
            />
            <Dropdown
              className="dropdown-healthcheck"
              id="drop2"
              options={services.map((service) => ({
                value: service.id,
                label: service?.name?.replace(/_/g, " "),
              }))}
              onSelect={handleSelectProvider}
              placeholder="All services"
              selected={selectedProvider || ""}
              disabled={selectedTypeDropdown && selectedTypeDropdown.toLowerCase() === "system"}
              tooltipText={"Please deselect System to enable this dropdown"}
            />
            <Dropdown
              className="dropdown-healthcheck"
              id="drop3"
              options={statusOptions}
              onSelect={handleSelectStatusDropdown}
              placeholder="Select status"
              selected={selectedStatusDropdown || ''}
            />
            <Dropdown
              className="dropdown-healthcheck"
              id="drop4"
              options={typeOptions}
              onSelect={handleSelectTypeDropdown}
              placeholder="Select type"
              selected={selectedTypeDropdown || ''}
              disabled={!!(selectedMarket || selectedProvider)}
              tooltipText="Please deselect Market and Service to enable this dropdown"
            />
            <div className="service-settings-filter-container">
              <button className="btn btn-primary clear-filter-btn" onClick={() => clearFilterHandler()}>
                Clear Filters
              </button>
            </div>
          </div>
        </div>
        {isDataEmpty(status) ? (
          <div className="container bg-white p-4 d-flex justify-content-center mw-100">
            <span className="text-muted">No data available</span>
          </div>
        ) : (<>
          <StatusTable statusData={tableData} searchKey={searchKey} />
          <div className="pagination-bar-healtcheck">
            <PaginationBar
              nPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={handlePageChange}
            />
          </div>
        </>
        )}
      </div>
    </div>
  );
};
export default Healthcheck;
