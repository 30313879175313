import "./kreditzProviders.css";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner"
import { getKreditzProviderList, fileKreditzProviders } from "../../Redux/Actions/KreditzProviderList";
import updateIcon from "../../assets/images/edit.svg";
import KreditzProvidersModal from '../KreditzProvidersModal/KreditzProvidersModal';
import ReactPaginate from 'react-paginate';
import searchIcon from '../../assets/images/search_icon.svg';
import NewFlash from "../FlashMessage/NewFlash";


const KreditzProvider = (props) => {
  const [openEditOrg, setOpenEditOrg] = useState(false);
  const [kreditData, setKreditData] = useState([]);
  const [kreditzId, setKreditzId] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [paginationData, setpaginationData] = useState({ current_page: 1, total_count: '', total_pages: '' })
  const [searchData, setSearchData] = useState('')
  const [flashMessage, setFlashMessage] = useState(false);
  const [flashData, setFlashData] = useState({
    iconImage: "",
    firstMsg: "",
    secondMsg: "",
  });

  useEffect(() => {
    ProvidersList();
  }, [page]);

  const closeFlash = () => {
    setFlashMessage(false);
  };

  const openFlashFn = async (iconImg, firstMessage, message) => {
    await dispatch(setLoaderSpinner(false));
    setFlashMessage(true);
    setFlashData((prevState) => ({
      ...prevState,
      iconImage: iconImg,
      firstMsg: firstMessage,
      secondMsg: message,
    }));
  }

  const ProvidersList = async (typeSearch) => {
    let query = ''
    let search = searchData && searchData.length > 0 ? searchData : ""

    query =
      '?page=' + page +
      '&query=' + search
    await dispatch(setLoaderSpinner(true))
    const data = await dispatch(getKreditzProviderList(query))
    setKreditData(data?.result?.data?.kreditz_providers)
    setpaginationData(prevState => ({
      ...prevState,
      current_page: data?.result?.data?.current_page,
      total_count: data?.result?.data?.total_count,
      total_pages: data?.result?.data?.total_pages
    }))
    await dispatch(setLoaderSpinner(false))
  }

  const handleSubmit = (id) => {
    setKreditzId(id);
    setOpenEditOrg(true)
  };

  const togglePopup = () => {
    setOpenEditOrg(false)
  }

  const handleUpdateIcon = async (data) => {
    const response = await dispatch(fileKreditzProviders(data))
    if (response.status && response.data !== undefined) {
      if (response.message === "Unauthorize") {
        history.push("/login")
      } else {
        let findINdex = kreditData.findIndex((item) => item.id === data.id)
        if (findINdex >= 0) {
          openFlashFn("tick", "Success", response.message)
          kreditData[findINdex].icon_url = response.data && response.data.kreditz_provider && response.data.kreditz_provider.icon_url
          setKreditData([...kreditData])
        }
        setOpenEditOrg(false)
      }
    } else {
      setOpenEditOrg(false)
      openFlashFn("", "Error", response.message)
    }
  }

  const searchHandle = (e) => {
    setSearchData(e.target.value)
  }

  const searchOrgSubmit = () => {
    ProvidersList();
    setPage(1)
  }

  return (
    <div className="kreditz-provider-container page-container" id="kreditz_provider_page">

      {flashMessage && (
        <div
          className={`alert - popup alert - success - bar ${flashMessage
            ? setTimeout(() => setFlashMessage(false), 3000) & "alert-shown"
            : "alert-hidden"
            } `}
        >
          <NewFlash data={flashData} closeFlash={() => closeFlash()} />
        </div>
      )}

      {
        openEditOrg ?
          <KreditzProvidersModal
            closeDelete={togglePopup}
            id={kreditzId}
            handleUpdateIcon={(e) => handleUpdateIcon(e)}
          />
          : null
      }

      <div className="lower-header-kreditz-provider">
        <h3>Kreditz Providers</h3>
        <div className="select-search tab-date">
          <div className="search-input org-search-input">
            <input
              className="search-input search-kreditz-provider"
              name="search"
              value={searchData}
              placeholder="Search by Bank Name"
              onChange={(e) => searchHandle(e)}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  searchOrgSubmit();
                }
              }}
            />
            <img className="search-icon" src={searchIcon} alt="searchIcon" onClick={() => searchOrgSubmit()} />
          </div>
        </div>
      </div>

      <div className="bg-white-organizations bg-20-top" style={{ margin: "20px 0 0 0" }}>
        <div className="organizations-thead-scroll-y">
          <table className="table" width="100%">
            <thead className="organizations-thead">
              <tr>
                <td className="org-head">Bank Name</td>
                <td className="org-head">Display Name</td>
                <td className="org-head">Provider Group ID</td>
                <td className="org-head">Icon</td>
                <td><center>Action</center></td>
              </tr>
            </thead>
            <tbody className="organizations-tbody">
              {
                kreditData && kreditData.length > 0 && kreditData.map((item) => {
                  return (
                    <tr key={`kreditz-providers-${item.id + 1} `}
                      id={`${item.id}`}>
                      <td className="org-td">{item.name}</td>
                      <td className="org-td">{item.display_name}</td>
                      <td className="org-td">{item.provider_group_id}</td>
                      <td className="org-td">
                        <div className="email-img-cover"><img src={item.icon_url} alt="view" width="40" /></div>
                      </td>
                      <td className="org-td">
                        <center>
                          <a name="kreditz_provider_handler" href="#kreditz_provider_handler"><img src={updateIcon} alt="view" width="20" onClick={(e) => handleSubmit(item.id)} /></a>
                        </center>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <div className="pagination-box">
          {paginationData.total_count > 30 &&
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              breakLabel={"..."}
              pageCount={Math.ceil(paginationData.total_pages)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(e) => setPage(e.selected + 1)}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={parseInt(page) - 1}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default KreditzProvider;