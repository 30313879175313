import axios from "axios";
import { API_CONFIG } from "../../config/api";
import { removeHeaderWithLocalstorage } from "../Actions/Auth";

export function getServiceSettings(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.SERVICE_SETTING.LIST()}${data}`;
  return (dispatch) =>
    axios
      .get(apiURL)
      .then((res) => {
        return { status: true, message: res.data.status, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function UpdateServiceSettingsAction(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.SERVICE_SETTING.UPDATE()}${data.id}`;
  return (dispatch) =>
    axios
      .put(apiURL, data)
      .then((res) => {
        return { status: true, message: res.data.status, renderMessage: res.data.message, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function CreateServiceSettings(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.SERVICE_SETTING.CREATE()}`;
  return (dispatch) =>
    axios
      .post(apiURL, data)
      .then((res) => {
        return { status: true, message: res.data.status, renderMessage: res.data.message, data: res.data.data };
      })
      .catch((err) => {
        err?.response?.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err?.response?.data.error || err?.response?.data?.message,
        };
      });
}

