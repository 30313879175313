import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import edit from '../../assets/images/edit.svg';
import ReactPaginate from 'react-paginate';
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';
import { getServiceProviderData, editServiceProviderData } from "../../Redux/Actions/ServiceProvider";
import FlashMessage from '../../Components/FlashMessage/FlashMessage';
import { setdisplayFlash, getdisplayFlash } from '../../Utils/Common';
import EditServiceProvider from "../EditServiceProvider/EditServiceProvider";
import SearchIcon from '../../assets/images/search_icon.svg'
import { clearFilterHandler } from "../../Utils/reloadPage";

const ServiceProvider = () => {
  const dispatch = useDispatch();
  let initialData = { "country_id": "", "service_id": "", "status": "", "type": "" }
  const history = useHistory();
  const [searchData, setSearchData] = useState('');
  const [paginationData, setpaginationData] = useState({ current_page: 1, total_count: '', total_pages: '' })
  const [country, setCountry] = useState([]);
  const [filters, setFilters] = useState(initialData);
  const [openEditService, setOpenEditService] = useState(false)
  const [itemForEdit, setItemForEdit] = useState()
  const [editFlashMessage, setEditFlashMessage] = useState(getdisplayFlash());
  const [editFlashData, setEditFlashData] = useState({ iconImage: "", firstMsg: "", secondMsg: "" })
  const [serviceProviders, setServiceProviders] = useState([])
  const [serviceList, setServiceList] = useState([])
  const [editData, setEditData] = useState({ status: '', allow_refetch_transactions: '', clear_credentials: '', sandbox_enabled: '' })
  const isFilterActive = ((filters.service_id && filters.service_id != "") || (filters.country_id && filters.country_id != "") || (filters.status && filters.status != "") || (filters.type && filters.type != ""))

  useEffect(() => {
    submitHandle();
  }, []);

  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setEditFlashMessage(true);
    setEditFlashData(prevState => ({
      ...prevState,
      iconImage: iconImage,
      firstMsg: firstMsg,
      secondMsg: secondMsg
    }))
  }

  const submitHandle = async (page = 1) => {
    const data = filters;
    let query = `?country_id=${data.country_id}&service_id=${data.service_id}&status=${data.status}&type=${data.type}&page=${page}&per_page=${50}&query=${searchData}`
    const resData = await dispatch(getServiceProviderData(query))
    const { service_providers, service_list, countries_list, current_page, next_page, total_count, total_pages } = resData.data
    setServiceProviders(service_providers)
    setServiceList(service_list)
    setCountry(countries_list)
    if (resData.status) {
      if (resData.message === "Unauthorize") {
        history.push("/login")
      } else {
        setpaginationData(prevState => ({
          ...prevState,
          current_page: current_page,
          total_count: total_count,
          total_pages: total_pages
        }))
      }
    }
    await dispatch(setLoaderSpinner(false))
  }

  const togglePopup = () => {
    setOpenEditService(false)
  }

  const handleChange = (type, value) => {
    setFilters(prevState => ({
      ...prevState,
      [type]: value
    }))
  };

  function humanize(str) {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  const editSubmitHandler = async (serviceStatus) => {
    if (serviceStatus?.status === "") {
      setOpenEditService(false)
      openFlash("alert", "Error!", "Please select status value Active or Inactive");
    } else {
      const response = await dispatch(editServiceProviderData(itemForEdit.id, serviceStatus))
      setOpenEditService(false)
      if (response.status) {
        openFlash("tick", "Done!", response.message)
        submitHandle()
      } else {
        openFlash("alert", "Error!", response.message);
      }
    }
  }

  const editHandler = async (item) => {
    setOpenEditService(true)
    setItemForEdit(item)
    setEditData(item)
  }

  function closeEditFlash() {
    setdisplayFlash(false);
  }

  const pageChange = (selectedPage) => {
    let newPage = selectedPage.selected + 1;
    submitHandle(newPage);
  }


  return (
    <div className="organization-providers-container page-container" id="organization_providers_page">

      <div className={`alert-popup alert-success-bar ${editFlashMessage ? setTimeout(() => setEditFlashMessage(false), 2000) & setdisplayFlash(false) & 'alert-shown' : 'alert-hidden'}`}>
        <FlashMessage
          iconImage={editFlashData.iconImage}
          firstMsg={editFlashData.firstMsg}
          secondMsg={editFlashData.secondMsg}
          closeFlash={() => closeEditFlash()}
        />
      </div>
      {
        openEditService && <EditServiceProvider
          editData={editData}
          setEditData={setEditData}
          confirm={editSubmitHandler}
          closeDelete={togglePopup}
        />
      }
      <div className="lower-header-kreditz-provider">
        <h3>Service Providers</h3>
      </div>
      <div className="filter-container-orgprovider">
        <div className="organization-provider-filter-container">
          <label>Service</label>
          <select className="select-filter-organization-provider capitalize" onChange={(e) => handleChange("service_id", e.target.value)}>
            <option value="">Select</option>
            {
              serviceList && serviceList.map((service, index) => (
                <option value={service.id}
                  key={`serviceList-option-${index + 1}`}>
                  {service.name}
                </option>
              ))
            }
          </select>
        </div>
        <div className="organization-provider-filter-container">
          <label>Country</label>
          <select className="select-filter-organization-provider capitalize" onChange={(e) => handleChange("country_id", e.target.value)}>
            <option value="">Select</option>
            {
              country && country.map((_country, index) => (
                <option value={_country.id}
                  key={`country-option-${index + 1}`}>
                  {_country.name}
                </option>
              ))
            }
          </select>
        </div>
        <div className="organization-provider-filter-container">
          <label>Certificate Type</label>
          <select className="select-filter-organization-provider" onChange={(e) => handleChange("type", e.target.value)}>
            <option value="">Select</option>
            <option value="private">Consumer</option>
            <option value="business">Corporate</option>
          </select>
        </div>
        <div className="organization-provider-filter-container">
          <label>Status</label>
          <select className="select-filter-organization-provider" onChange={(e) => handleChange("status", e.target.value)}>
            <option value="">Select</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="unverified">Unverified</option>
          </select>
        </div>
        <div className="organization-provider-filter-container">
          <button className={isFilterActive ? "apply-filter-button" : "apply-filter-button login_submit_disabled"} onClick={() => {
            submitHandle(1)
          }}>
            Filter
          </button>
        </div>
        <div className="organization-provider-filter-container">
          <button className={isFilterActive ? "apply-filter-button" : "apply-filter-button login_submit_disabled"} onClick={() => {
            clearFilterHandler()
          }}>
            Clear Filters
          </button>
        </div>
        <div className="select-search tab-date ms-auto">
          <div className="search-input org-search-input">
            <input
              className="search-input search-kreditz-provider"
              name="search"
              value={searchData}
              placeholder="Search by Identifier"
              onChange={(e) => setSearchData(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  submitHandle();
                }
              }}
            />
            <img className="search-icon" src={SearchIcon} alt="SearchIcon" onClick={() => submitHandle()} />
          </div>
        </div>
      </div >
      <div className="bg-white-organizations bg-20-top" style={{ margin: "20px 0 0 0" }}>
        <div className="organizations-thead-scroll-y">
          <table className="table" width="100%">
            <thead className="organizations-providers-thead">
              <tr>
                <td className="org-head">Service</td>
                <td className="org-head width-td-100">Country</td>
                <td className="org-head width-td-100">Type</td>
                <td className="org-head width-td-100">Status</td>
                <td className="org-head">Identifier</td>
                <td className="org-head">Clear Credential</td>
                <td className="org-head">Sandbox Enabled</td>
                <td className="org-head">Allow Re-fetch Transactions</td>
              </tr>
            </thead>
            <tbody className="organizations-tbody">
              {
                serviceProviders && serviceProviders.length > 0 && serviceProviders.map((item) => {
                  return (
                    <tr key={`tr-op-${item.id + 1} `} id={`op-${item.id}`}>
                      <td className="org-td">{humanize(item.service.name)}</td>
                      <td className="org-td">{humanize(item.country.name)}</td>
                      <td className="org-td">{humanize(item.type === 'private' ? 'consumer' : 'coporate')}</td>
                      <td className="org-td">{humanize(item.status)}</td>
                      <td className="org-td">{item.identifier}</td>
                      <td className="org-td">{item.clear_credentials ? 'True' : 'False'}</td>
                      <td className="org-td">{item.sandbox_enabled ? 'True' : 'False'}</td>
                      <td className="org-td">{item.allow_refetch_transactions ? 'True' : 'False'}</td>
                      <td className="org-td org-action">
                        <center>
                          <a href="javascript:void(0)">
                            <img className="email-img" src={edit} alt="edit" width="20" onClick={(e) => editHandler(item)} />
                          </a>
                        </center >
                      </td >
                    </tr >
                  )
                })
              }
            </tbody >
          </table >
        </div >
        <div className="pagination-box">
          {paginationData.total_count > 50 &&
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              breakLabel={"..."}
              pageCount={Math.ceil(paginationData.total_pages)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={pageChange}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={paginationData.current_page - 1}
            />
          }
        </div>
      </div >
    </div >
  );
};

export default ServiceProvider;