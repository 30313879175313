import React from 'react';
import Select from 'react-select'
import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { createOrganizationProvider } from '../../Redux/Actions/OrganizationProvider';

const CreateOrganizationProvider = (props) => {
  let newKreditzProviderList = props.kreditzProviderList.map(item => ({ "value": item.id, "label": item.display_name }))
  const attachData = { update_icon: "", icon: "" }
  const dispatch = useDispatch();
  const history = useHistory();
  const [orggId, setOrggId] = useState(props.orgId);
  const [servicesId, setServicesId] = useState(props.serviceId);
  const [countris, setCountries] = useState(props.country);
  const [selectedOrdID, setSelectedOrdId] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCertificateType, setSelectedCertificateType] = useState("");
  const [status, setStatus] = useState();
  const [authenticationType, setAuthenticationType] = useState(null);
  const [fileData, setFileData] = useState(attachData);
  const [KreditzProviderId, setKreditzProviderId] = useState("")

  const handleAttachmentChange = (event, index) => {
    let value = event.target.files[0];
    let file_name = value.name
    if (value) {
      setFileData(prevState => ({
        ...prevState,
        update_icon: file_name
      }))

      let reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = function () {
        setFileData(prevState => ({
          ...prevState,
          icon: reader.result
        }))
      };
      reader.onerror = function (error) {
      };
    }
  }

  const submitHandle = async (e) => {
    e.preventDefault()
    const data = {
      organization_id: selectedOrdID,
      kreditz_provider_id: KreditzProviderId,
      country_id: selectedCountry,
      type: selectedCertificateType,
      icon: fileData?.icon,
      service_id: selectedServiceId,
      active: JSON.parse(status),
      banking_authentication_type: authenticationType
    }
    const response = await dispatch(createOrganizationProvider(data))
    if (response.status) {
      if (response.message === "Unauthorize") {
        history.push("/login")
      } else {
        props.submitHandle(1)
        props.closeDelete(false);
        props.openFlash("tick", "Done!", response.message);
      }
    } else {
      props.closeDelete(false);
      props.openFlash("alert", "Error!", response.message);
    }
  }

  const kreditzProviderHandler = (selected) => {
    setKreditzProviderId(selected)
  }



  return (
    <div className='EditPopup'>
      <div className='EditPopup_inner create-organization-provider'>
        <h3>Create Organization Provider</h3>
        <form onSubmit={submitHandle}>
          <div className="row">
            <div className="col-md-12">
              <div className="organization-provider-filter-container">
                <label>Kreditz Provider</label>
                <Select className="kr-provider-select" onChange={(e) => kreditzProviderHandler(e.value)} options={newKreditzProviderList} required />
              </div>
            </div>
            <div className="col-md-6">
              <div className="organization-provider-filter-container">
                <label>Organization</label>
                <select className="select-filter-organization-provider" onChange={(e) => setSelectedOrdId(e.target.value)} required>
                  <option value="">Select</option>
                  {
                    orggId && orggId.map((oid, index) => (
                      <option value={oid.id} key={`orggId-option-${index + 1}`}>
                        {oid.name} ({oid.id})
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="organization-provider-filter-container">
                <div className="input-credentials">
                  <label>Icon</label>
                  <div className="provider-input-file">
                    <input className='kreditz-provider-select' type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => handleAttachmentChange(e, 0)} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="organization-provider-filter-container">
                <label>Service Provider</label>
                <select className="select-filter-organization-provider" onChange={(e) => setSelectedServiceId(e.target.value)} required>
                  <option value="">Select</option>
                  {
                    servicesId && servicesId.map((service, index) => (
                      <option value={service.id} key={`servicesId-option-${index + 1}`}>
                        {props.humanize(service.name)}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="organization-provider-filter-container">
                <label>Country</label>
                <select className="select-filter-organization-provider" onChange={(e) => setSelectedCountry(e.target.value)} required>
                  <option value="">Select</option>
                  {
                    countris && countris.map((_country, index) => (
                      <option value={_country.id} key={`countris-option-${index + 1}`}>
                        {props.humanize(_country.name)}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="organization-provider-filter-container">
                <label>Certificate Type</label>
                <select className="select-filter-organization-provider" onChange={(e) => setSelectedCertificateType(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="private">Consumer</option>
                  <option value="business">Corporate</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="organization-provider-filter-container">
                <label>Active</label>
                <select className="select-filter-organization-provider" onChange={(e) => setStatus(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="organization-provider-filter-container">
                <label>Authentication Type</label>
                <select className="select-filter-organization-provider" onChange={(e) => setAuthenticationType(e.target.value)}>
                  <option value="">Select</option>
                  <option value="decoupled">Decoupled</option>
                  <option value="redirect">Redirect</option>
                </select>
              </div>
            </div>
            <div className="modal-btns">
              <button className='closeDelete-update' style={{ marginLeft: '0px' }} onClick={props.closeDelete}>Cancel</button>
              <button className='confirm-update' type='submit'>Confirm</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateOrganizationProvider;