import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import _ from "lodash"
import { EditorState, convertFromHTML, ContentState, compositeDecorator, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html'
import { getBankCustomMessage, getBankDetails } from '../../../Redux/Actions/OrganizationManagement';
import { getBankPriorityUpdate } from '../../../Redux/Actions/OrganizationProvider';
import { stateFromHTML } from 'draft-js-import-html';

function BankListTab({
  clientDetails,
  bankFilters,
  setBankFilters,
  languages,
  bankFilterCountries,
  bankFilterCountriesCorporate,
  country,
  banks,
  setBankSearch,
  bankSearch,
  setBanks,
  fetchBankList,
  setFoundElement,
  showMessage,
  foundElement,
  setCustomBankDetails,
  customBankDetails,
  organization_id,
  setShowMessage,
  _updateBankCustomMessage,
  searchIcon,
  openFlash
}) {
  const [currentBankId, setCurrentBankId] = useState(null)
  const dispatch = useDispatch()
  const BankList = useSelector(state => state.organization_management?.bank_list);
  const initialElement = {}
  var editorState = EditorState.createEmpty()
  const [description, setDescription] = useState(editorState);
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [bankDataDetails, setBankDataDetails] = useState({})
  const [editorMsg, setEditorMsg] = useState()


  const handleChangeType = (e) => {
    const { name, value } = e.target
    setBankFilters(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = async (e) => {
    const copyListItems = [...banks];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setBanks(copyListItems);
    let bank = copyListItems.map((item, index) => ({ organization_provider_id: item.organization_provider_id, index: index }))
    let bankData = { bank }
    const priorityResponse = await dispatch(getBankPriorityUpdate(bankData))
    const { status, message } = priorityResponse;
    if (status) {
      openFlash("tick", "Done!", message)
    }
  };

  const handleBankSearch = (e) => {
    setBankSearch(e.target.value)
    if (e.target.value !== '') {
      let _banks = _.filter(BankList, function (f) {
        if (f.display_name.toLowerCase().match(e.target.value.toLowerCase())) {
          return f
        }
      })
      setBanks(_banks)
    } else {
      fetchBankList()
    }
  }


  const htmlOptions = {
    inlineStyles: {
      UNDERLINE: { element: 'ins' },
    },
  };


  const convertMessageToEditorState = (message) => {
    const contentState = stateFromHTML(message, htmlOptions);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const expandMessage = async (id, bankData) => {
    setFoundElement(initialElement);
    setCurrentBankId(id);

    bankData?.message ? setShowMessage(true) : setShowMessage(false);
    setBankDataDetails(bankData)
    const convertedDataMsg = convertMessageToEditorState(bankData?.message?.custom_message ? bankData?.message?.custom_message : bankData?.message?.default_message)
    setDescription(convertedDataMsg);
  };

  const setEditorState = (editorState) => {
    const convertedData = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(convertedData);
    setEditorMsg(markup)
    setDescription(editorState)
  }

  return (
    <div id="banklist" className="hide">
      <div className="detail-two-box row">
        <div className="col details-left details-full-box">
          <h3 className="headeing-switch">Notification message for banks</h3>
          <div className="form-group">
            <label>Select type of bank flow</label>
            <select name="type" value={bankFilters.type} className="form-control bankflow-form org-excludeDropdown" onChange={(e) => handleChangeType(e)}>
              {
                clientDetails.market_ids && clientDetails.market_ids.length > 0 && clientDetails.customer_enabled && (
                  <option value="private" >Consumer</option>
                )
              }
              {
                clientDetails.corporate_market_ids && clientDetails.corporate_market_ids.length > 0 && clientDetails.corporate_enabled && (
                  <option value="business" >Business</option>
                )
              }
            </select>
          </div>
          <br></br>
          <div className="form-group">
            <label>Select language</label>
            <select name="locale" value={bankFilters.locale} className="form-control bankflow-form org-excludeDropdown" onChange={(e) => handleChangeType(e)}>
              {languages.map((lang, index) =>
                <option
                  className='admin-option'
                  key={`admin - option - ${index + 1}`}
                  value={lang.code}>
                  {lang.name}
                </option>
              )};
            </select>
          </div>
          <br></br>
          <div className="form-group">
            <label>Select market</label>
            <select name="country_id" value={bankFilters.country_id} className="form-control bankflow-form org-excludeDropdown" onChange={(e) => handleChangeType(e)}>
              {
                bankFilters?.type === "private" ? (
                  clientDetails?.market_ids && clientDetails?.market_ids?.length > 0 ? (
                    bankFilterCountries.map((_country, index) =>
                      <option
                        className='admin-option'
                        key={`admin - option - ${index + 1}`}
                        value={_country.id}>
                        {_country.name}
                      </option>
                    )) :
                    (
                      country.map((_country, index) =>
                        <option
                          className='admin-option'
                          key={`admin - option - ${index + 1}`}
                          value={_country.id}>
                          {_country.name}
                        </option>
                      )
                    )) : (clientDetails?.corporate_market_ids && clientDetails?.corporate_market_ids?.length > 0 ? (
                      bankFilterCountriesCorporate.map((_country, index) =>
                        <option
                          className='admin-option'
                          key={`admin - option - ${index + 1}`}
                          value={_country.id}>
                          {_country.name}
                        </option>
                      )) :
                      (
                        country.map((_country, index) =>
                          <option
                            className='admin-option'
                            key={`admin - option - ${index + 1}`}
                            value={_country.id}>
                            {_country.name}
                          </option>
                        )
                      ))
              }
            </select>
          </div>
        </div>
        <div className="col details-middle details-full-box">
          <h3 className="headeing-switch">Select bank</h3>
          <div className="select-search tab-date">
            <div className="search-bankflow-input">
              <input
                className="search-bankflow-input"
                name="search"
                placeholder="Search your bank"
                value={bankSearch}
                onChange={(e) => handleBankSearch(e)}
              />
              <img className="search-bankflow-icon" src={searchIcon} alt="searchIcon" />
            </div>
          </div>
          <div className="bank-overflow-scroll">
            {banks && banks.map((_bank, index) => (
              <div className="banks-boxes"
                onClick={(e) => expandMessage(_bank?.kreditz_provider_id, _bank)}
                draggable
                onDragStart={(e) => dragStart(e, index)}
                onDragEnter={(e) => dragEnter(e, index)}
                onDragEnd={drop}
              >
                <figure><img className="banks-icon" src={_bank.logo && _bank.logo.url} alt="bankkicon" /></figure>
                <div className="bank-action-icons">
                  <p title={_bank.display_name}>{_bank.display_name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col details-right details-full-box">
          <h3 className="headeing-switch">Message</h3>
          {showMessage ?
            <div>
              <span className="bank-message-header"><img className="banks-icon" src={bankDataDetails.logo && bankDataDetails.logo.url} alt="bankHeader" />{bankDataDetails.display_name}</span>
              <br></br>
              <p className="message-entering">Enter message</p>
              <div className="input-info-bank">
                <Editor
                  editorState={description}
                  onEditorStateChange={setEditorState}
                  toolbar={{
                    options: ['inline'],
                    inline: {
                      options: ['bold', 'italic', 'underline'],
                    },
                  }}
                />
              </div>
              <button className="update-bank-message" onClick={() => _updateBankCustomMessage(editorMsg, bankDataDetails)}>Update</button>
            </div> :
            null
          }
        </div>
      </div>
    </div>
  );
}

export default BankListTab;
