import { useEffect, useState } from "react";
import BankStatusLabel from "./utilComponents/BankStatusBankLabel";
import BankStatusBar from "./utilComponents/BankStatusBar"
import ReactPaginate from "react-paginate";

import { useDispatch } from "react-redux";
import { getBanksData, getRequestsFromBanks } from "../../Redux/Actions/BankStatus";
import { blockSorting } from "./utilities/dataFormatting";



const BankColumn = (props) => {
  const [bank, setBank] = useState(props.data)
  const [expanded, setExpanded] = useState(false);
  const [dataBlocks, setDataBlocks] = useState();
  const [recentStatus, setRecentStatus] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getRequests = async (time_frame) => {
    if (loading) return;
    setLoading(true);
    try {
      const requests = props.requests || [];
      if (props.data.kreditz_provider_id === 15) {
      }
      const { recentRequests } = blockSorting(requests, time_frame)
      await setRecentStatus(recentRequests);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const getFullRequests = async (time_frame) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await dispatch(getRequestsFromBanks({ params: { country_id: props.data.country_id, kreditz_provider_id: props.data.kreditz_provider_id, time_frame } }));
      const { blocks } = await blockSorting(await res.data.requests, time_frame);
      await setDataBlocks(blocks);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setExpanded(true);
    }
  }
  const toggleExpanded = () => {
    setExpanded(!expanded);
  }
  const handleClick = async () => {
    if (!dataBlocks) {
      await getFullRequests(props.timeFrame);
    } else {
      toggleExpanded();
    }
  }
  const showUptime = () => {
    if (dataBlocks) {
      return true;
    }
    return false;

  }
  useEffect(() => {
    setExpanded(false);
    setDataBlocks(null);
    setBank(props.data);
    getRequests(props.timeFrame);
  }, [props.data, props.requests, props.timeFrame]);

  return (
    <>
      <div className={`mw-100 bank-status-column ${expanded ? "bank-status-column-expanded" : ""} `}>
        <span onClick={handleClick} className="mw-100 bank-status-column-inner">
          <BankStatusLabel bank={bank} status={dataBlocks} showUptime={showUptime} timeFrame={props.timeFrame} recentRequests={recentStatus} />
        </span>
        {loading && <div className="bank-status-column-loading">
          <div className="bank-status-column-loading-indicator"></div>
        </div>}
        {dataBlocks && <span className="mw-100 bank-status-column-bar">
          <BankStatusBar blocks={dataBlocks} timeFrame={props.timeFrame} />
        </span>
        }
      </div>
    </>
  )
}

const BankStatusList = ({ query, timeFrame }) => {
  const [data, setData] = useState();
  const [total_pages, setTotal_pages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [requests, setRequests] = useState();
  const [newQuery, setNewQuery] = useState(false);
  const dispatch = useDispatch()
  useEffect(async () => {
    try {
      setNewQuery(true);
      await fetchBankStatus(query, page);
    } finally {
      setNewQuery(false);
    }
  }, [query, timeFrame]);

  const fetchBankStatus = async (params, page) => {
    if (loading) return;
    setLoading(true);
    try {
      params = { country_id: params.country_id, page };
      const res = await dispatch(getBanksData({ params }))
      await setTotal_pages(Math.ceil(res.data.total / 20));
      await setData(res.data.banks);
      await setRequests(res.data.request_list);


    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }


  }

  const selectPageHandler = (event) => {
    const currentPage = event.selected + 1;
    setPage(currentPage);
    fetchBankStatus(query, currentPage);
  };


  if (!data || !requests || newQuery) {
    return (
      <div className="w-100 m-0 bank-status-list-container p-2">

      </div>)
  }

  return (
    <>

      <div className="w-100 m-0 bank-status-list-container p-2">
        {loading && data && <div className="bank-status-list-loading">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>}
        <div className="w-100 bank-status-list-header">
          <span className="bank-status-list-header-text">Banks</span>
        </div>
        {data.map(((bank, key) => { return <BankColumn data={bank} key={key} timeFrame={timeFrame} requests={requests[bank.kreditz_provider_id.toString()]} /> }))}
        <div className="pagination-wrapper">
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel={"..."}
            pageCount={total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={selectPageHandler}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </>
  )
}

export default BankStatusList;