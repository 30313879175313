import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubscribersList,
  getBasicValues,
} from "../../Redux/Actions/TestCenter";
import { getdisplayFlash, setdisplayFlash } from "../../Utils/Common";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import FlashMessage from "../FlashMessage/FlashMessage";
import Row from "./Row";
import _ from "lodash";

// LIBRARIES
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

// ASSETS
import dropdownIcon from "./assets/kreditz-dropdown-icon-grey.svg";
import totalRequestsIcon from "./assets/kreditz-edit-icon.png";
import acceptedRequestsIcon from "./assets/kreditz-check-icon.png";
import pendingRequestsIcon from "./assets/kreditz-hourglass-icon.png";

// STYLES
import "./styles/testCenterView.css";
import "./styles/MaterialUI.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { clearFilterHandler } from "../../Utils/reloadPage";

const TestCenterView = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const subscribersData = useSelector(
    (state) => state.test_centers?.subscribers_list
  );

  const statisticsData = useSelector((state) => state.test_centers?.statistics);
  const countriesData = useSelector((state) => state.test_centers?.countries);

  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
  const [iconImage, setIconImage] = useState("tick");
  const [firstMsg, setFirstMsg] = useState("Done!");
  const [secondMsg, setSecondMsg] = useState(
    "Subscriber has been created successfully"
  );
  const [subscriberName, setSubscriberName] = useState("");
  const [subscriberSource, setSubscriberSource] = useState("");
  const [subscriberProduct, setSubscriberProduct] = useState("");
  const [subscriberType, setSubscriberType] = useState("");
  const [subscriberMarket, setSubscriberMarket] = useState("");
  const [subscriberStatusActivity, setSubscriberStatusActivity] = useState("");
  const [currentSubscribers, setCurrentSubscribers] = useState([]);
  const [subscribers, setSubscribers] = useState(subscribersData);
  const [statistics, setStatistics] = useState(statisticsData);
  const [countries, setCountries] = useState(countriesData);

  const [_dateRange, setDateRange] = useState(
    `${getStartDate()} - ${getEndDate()}`
  );
  const expandFilters = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };

  const highlight = () => {
    isActive ? setIsActive(false) : setIsActive(true);
  };

  const expandHandler = () => {
    expandFilters();
    highlight();
  };

  const applyBtnHandler = (_, picker) => {
    const startDate = picker.startDate.format("DD/MM/YYYY");
    const endDate = picker.endDate.format("DD/MM/YYYY");
    setDateRange(`${startDate} - ${endDate}`);
  };

  const fetchSubsribersData = async () => {
    dispatch(setLoaderSpinner(true));
    let query = "";
    query = "?test_date_range=" + _dateRange;

    const data = await dispatch(getSubscribersList(query));
    if (data.status) {
      setSubscribers([...data.data.subscribers]);
      setStatistics(data.data.statistics);
      let finalData = _.orderBy(data.data.subscribers, "created_at", "desc");
      setCurrentSubscribers([...finalData]);
    }

    dispatch(setLoaderSpinner(false));
  };

  const fetchBasicValues = async () => {
    const data = await dispatch(getBasicValues());
    if (data.status) {
      setCountries(data.data.countries);
    }
  };

  useEffect(() => {
    fetchBasicValues();
    fetchSubsribersData();
  }, [_dateRange]);

  const closeFlash = () => {
    setdisplayFlash(false);
    setFlashMessage(false);
  };

  const openFlash = () => {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  };

  const names = subscribers?.map((subscriber) => subscriber.full_name);
  const filteredNames = [...new Set(names)];

  const sources = subscribers?.map((subscriber) => subscriber.send_by);
  const filteredSources = [...new Set(sources)];

  const products = subscribers?.map((subscriber) => subscriber.request_type);
  const filteredProducts = [...new Set(products)];

  const types = subscribers?.map((subscriber) => subscriber.product);
  const filteredTypes = [...new Set(types)];

  const subscriberCountryIDs =
    subscribers && subscribers?.map(({ country_id }) => country_id);
  const countryNamesToShow = countries
    .filter(({ id }) => subscriberCountryIDs?.includes(id))
    .map(({ name }) => `${name.charAt(0).toUpperCase()}${name.slice(1)}`);

  const selectStatusActivityHandler = (e) => {
    const currentStatusActivity = e.target.value;
    setSubscriberStatusActivity(currentStatusActivity);
  };

  const updateFormHandler = (e) => {
    e && e.preventDefault();
    dispatch(setLoaderSpinner(true));
    let subscribersToDisplay = subscribers;
    if (subscriberName) {
      subscribersToDisplay = subscribersToDisplay.filter(
        (subscriber) => subscriber.full_name === subscriberName
      );
    }

    if (subscriberSource) {
      subscribersToDisplay = subscribersToDisplay.filter(
        (subscriber) => subscriber.send_by === subscriberSource
      );
    }

    if (subscriberType) {
      subscribersToDisplay = subscribersToDisplay.filter(
        (subscriber) => subscriber.product === subscriberType
      );
    }

    if (subscriberMarket) {
      subscribersToDisplay = subscribersToDisplay.filter(
        (subscriber) =>
          countries.find(({ id }) => id === subscriber.country_id).name ===
          subscriberMarket.toLowerCase()
      );
    }

    if (subscriberStatusActivity) {
      const inactiveSubscriberIDs = [];
      const todayDate = moment(new Date());
      subscribers.forEach((subscriber) => {
        if (!subscriber.continuously) {
          const subscriberEndDate = moment(new Date(subscriber.end_date));
          const difference = todayDate.diff(subscriberEndDate, "days");
          if (difference > 0) {
            inactiveSubscriberIDs.push(subscriber.id);
          }
        }
      });

      if (subscriberStatusActivity === "inactiveSubscriber") {
        subscribersToDisplay = subscribersToDisplay.filter((subscriber) =>
          inactiveSubscriberIDs.includes(subscriber.id)
        );
      } else {
        subscribersToDisplay = subscribersToDisplay.filter(
          (subscriber) => !inactiveSubscriberIDs.includes(subscriber.id)
        );
      }
    }
    subscribersToDisplay = _.orderBy(
      subscribersToDisplay,
      "created_at",
      "desc"
    );
    setCurrentSubscribers(subscribersToDisplay);
    dispatch(setLoaderSpinner(false));
  };
  function getStartDate() {
    return moment(new Date(new Date().getFullYear(), 0, 1)).format(
      "DD/MM/YYYY"
    );
  }

  function getEndDate() {
    return moment(new Date()).format("DD/MM/YYYY");
  }

  return (
    <div className="tc-page-container">
      <div
        className={`alert-popup alert-success-bar ${flashMessage
          ? setTimeout(() => setFlashMessage(false), 3000) &
          setdisplayFlash(false) &
          "alert-shown"
          : "alert-hidden"
          }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          openFlash={() => openFlash()}
          closeFlash={() => closeFlash()}
        />
      </div>
      <div className="tc-table-wrapper">
        <div className="tc-top">
          <h3 className="tc-h3">Test Center</h3>
          <div className="tc-btns">
            <button className="tests-btn" type="button">
              Tests
            </button>
            <button
              className="subs-btn"
              type="button"
              onClick={() => history.push({ pathname: "/activeSubscribers" })}
            >
              Subscribers
            </button>
            <div className="create-subs-btn-div">
              <button
                className="create-subs-btn"
                type="button"
                onClick={() => history.push({ pathname: "/createSubscriber" })}
              >
                Create a Subscriber
              </button>
            </div>
          </div>
        </div>
        <main className="tc-white-bg">
          <aside className="top-row">
            <h5 className="statisctics-text">Statistics</h5>
            <div className="filters-and-calendar-div">
              <button
                className={
                  isActive ? "filters-btn-secondary" : "filters-btn-primary"
                }
                type="button"
                onClick={expandHandler}
              >
                Filters
              </button>
              <div className="calendar-div">
                <DateRangePicker
                  onApply={applyBtnHandler}
                  initialSettings={{
                    startDate: getStartDate(),
                    endDate: getEndDate(),
                    locale: { format: "DD/MM/YYYY" },
                  }}
                >
                  <input
                    type="text"
                    id="tc-date-range-picker"
                    className="calendar"
                  />
                </DateRangePicker>
                <div className="tc-calendar-icon">
                  <FontAwesomeIcon
                    className="tc-icon-position"
                    icon={faCalendar}
                    size="1x"
                    color="#e8edfa"
                  />
                </div>
              </div>
            </div>
          </aside>

          {isOpen ? (
            <div className="tc-top-row">
              <div className="tc-input-wrapper">
                <p className="tc-p">Name</p>
                <select
                  id="tc-select"
                  className="tc-input"
                  value={subscriberName}
                  onChange={(e) => {
                    setSubscriberName(
                      e.target.value === "selectName" ? "" : e.target.value
                    );
                  }}
                >
                  <option value="selectName">Select</option>
                  {filteredNames.map((name) => (
                    <option key={`name - option - ${name + 1} `} value={name}>
                      {name}
                    </option>
                  ))}
                  ;
                </select>
                <img
                  src={dropdownIcon}
                  className="tc-dropdown-icon"
                  alt="dropdown icon"
                />
              </div>
              <div className="tc-input-wrapper">
                <p className="tc-p">Source</p>
                <select
                  id="tc-select"
                  className="tc-input"
                  value={subscriberSource}
                  onChange={(e) => {
                    setSubscriberSource(
                      e.target.value === "selectSource" ? "" : e.target.value
                    );
                  }}
                >
                  <option value="selectSource">Select</option>
                  {filteredSources.map((source) => (
                    <option
                      key={`source - option - ${source + 1} `}
                      value={source}
                    >
                      {source}
                    </option>
                  ))}
                  ;
                </select>
                <img
                  src={dropdownIcon}
                  className="tc-dropdown-icon"
                  alt="dropdown icon"
                />
              </div>
              <div className="tc-input-wrapper">
                <p className="tc-p">Product</p>
                <select
                  id="tc-select"
                  className="tc-input"
                  value={subscriberProduct}
                  onChange={(e) => {
                    setSubscriberProduct(
                      e.target.value === "selectProduct" ? "" : e.target.value
                    );
                  }}
                >
                  <option value="selectProduct">Select</option>
                  {filteredProducts.map((product) => (
                    <option
                      key={`product - option - ${product + 1} `}
                      value={product}
                    >
                      {product
                        ?.replace("customer", "Consumer")
                        .replace("corporate", "Corporate")}
                    </option>
                  ))}
                  ;
                </select>
                <img
                  src={dropdownIcon}
                  className="tc-dropdown-icon"
                  alt="dropdown icon"
                />
              </div>
              <div className="tc-input-wrapper">
                <p className="tc-p">Type</p>
                <select
                  id="tc-select"
                  className="tc-input"
                  value={subscriberType}
                  onChange={(e) => {
                    setSubscriberType(
                      e.target.value === "selectType" ? "" : e.target.value
                    );
                  }}
                >
                  <option value="selectTypes">Select</option>
                  {filteredTypes.map((type) => (
                    <option key={`type - option - ${type + 1} `} value={type}>
                      {type
                        ?.replace("certificate", "Certificate")
                        .replace("account_only", "Account only")}
                    </option>
                  ))}
                  ;
                </select>
                <img
                  src={dropdownIcon}
                  className="tc-dropdown-icon"
                  alt="dropdown icon"
                />
              </div>
              <div className="tc-input-wrapper">
                <p className="tc-p">Market</p>
                <select
                  id="tc-select"
                  className="tc-input"
                  value={subscriberMarket}
                  onChange={(e) => {
                    setSubscriberMarket(
                      e.target.value === "selectMarket" ? "" : e.target.value
                    );
                  }}
                >
                  <option value="selectMarkets">Select</option>
                  {countryNamesToShow.map((countryName) => (
                    <option
                      key={`coutry - option - ${countryName + 1} `}
                      value={countryName}
                    >
                      {countryName}
                    </option>
                  ))}
                  ;
                </select>
                <img
                  src={dropdownIcon}
                  className="tc-dropdown-icon"
                  alt="dropdown icon"
                />
              </div>
              <div className="tc-input-wrapper">
                <p className="tc-p">Subscribers</p>
                <select
                  id="tc-select"
                  className="tc-input"
                  value={subscriberStatusActivity}
                  onChange={selectStatusActivityHandler}
                >
                  <option value="Select">Select</option>
                  <option value="activeSubscriber">Active</option>
                  <option value="inactiveSubscriber">Inactive</option>
                </select>
                <img
                  src={dropdownIcon}
                  className="tc-dropdown-icon"
                  alt="dropdown icon"
                />
              </div>
              <div className="tc-input-wrapper">
                <button
                  className="tc-apply-btn"
                  type="button"
                  onClick={updateFormHandler}
                >
                  Apply
                </button>
              </div>
              <div className="tc-input-wrapper">
                <button
                  className="btn btn-primary clear-filter-btn"
                  type="button"
                  onClick={clearFilterHandler}
                >
                  Clear Filters
                </button>
              </div>
            </div>
          ) : null}

          <aside className="middle-row">
            <div className="div-one">
              <section className="section-one">
                <p className="tc-p">Total Requests</p>
                <h4>{statistics?.total}</h4>
                <img
                  src={totalRequestsIcon}
                  className="icon-one"
                  alt="edit icon"
                />
              </section>
            </div>
            <div className="div-two">
              <section className="section-two">
                <p className="tc-p">Accepted Requests</p>
                <h4 className="tc-h4">{statistics?.accepted}</h4>
                <img
                  src={acceptedRequestsIcon}
                  className="icon-two"
                  alt="check icon"
                />
              </section>
            </div>
            <div className="div-three">
              <section className="section-three">
                <p className="tc-p">Pending Requests</p>
                <h4 className="tc-h4">
                  {!statistics?.pending ? 0 : statistics?.pending}
                </h4>
                <img
                  src={pendingRequestsIcon}
                  className="icon-three"
                  alt="hourglass icon"
                />
              </section>
            </div>
          </aside>
          <aside className="bottom-row">
            <h5>Request Log</h5>
            <input
              id="tc-search"
              placeholder="Enter name"
              autoComplete="off"
              type="text"
            />
          </aside>
          <div className="table-responsive">
            <table className="table">
              <thead className="tc-thead">
                <tr>
                  <th className="tc-name">Name</th>
                  <th className="tc-source">Source</th>
                  <th className="tc-product">Product</th>
                  <th className="tc-type">Type</th>
                  <th className="tc-market">Market</th>
                  <th className="tc-from-to">From - To</th>
                  <th className="tc-requests">Requests Accepted</th>
                </tr>
              </thead>
              <tbody>
                {currentSubscribers?.map((subscriber) => (
                  <Row
                    key={subscriber.id}
                    subscriber={subscriber}
                    countries={countries}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  );
};

export default TestCenterView;
