import DateRangePicker from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import "./certificateInformation.scss"
import { getOrganizationAccountList } from "../../Redux/Actions/OrganizationManagement";
import { getCertificateInformation, getCertificateInformationFilter } from "../../Redux/Actions/CertificateInformation";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import Select from "react-select";
import moment from "moment";
import CertificateInformationTable from "./CertificateInformationTable";
import { useHistory } from "react-router-dom";
import CertificateSwitcher from "./CertificateSwitcher";
import { clearFilterHandler } from "../../Utils/reloadPage";

const CertificateInformation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [organizationList, setOrganizationList] = useState([])
  const [selectedOrgSelect, setSelectedOrgSelect] = useState()
  const [selectedOrg, setSelectedOrg] = useState(history.location.state?.selectedOrg ?? "")
  const [orgData, setOrgData] = useState([]);
  const [market, setMarket] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(history.location.state?.selectedMarket ?? "");
  const [itemOffset, setItemOffset] = useState(0);
  const [showAcceptedOnly, setShowAcceptedOnly] = useState(history.location.state?.showAcceptedOnly ?? false);

  let date = new Date();
  const filteredOrgData = showAcceptedOnly ? orgData.filter((item) => item.status === "accepted") : orgData;
  const selectedMarketName = market.find(index => index.id === selectedMarket)?.name;

  function getStartDate(d) {
    let firstDate;
    if (history.location.state?.selectedDateRange !== undefined) {
      firstDate = moment(history.location.state?.selectedDateRange.slice(0, 10), 'DD/MM/YYYY', true).format()
    }
    else {
      firstDate = d;
    }
    return moment(firstDate).format("DD/MM/YYYY");
  }

  function getEndDate(d) {
    let secondDate;
    if (history.location.state?.selectedDateRange !== undefined) {
      secondDate = moment(history.location.state?.selectedDateRange.slice(11, 21), 'DD/MM/YYYY', true).format()
    }
    else {
      secondDate = d;
    }
    return moment(secondDate).format("DD/MM/YYYY");
  }

  const startDate = getStartDate(date);
  const endDate = getEndDate(date);
  const [selectedDateRange, setSelectedDateRange] = useState(`${startDate}-${endDate}`);

  const fetchOrgList = async () => {
    let query = "?include_all=" + true
    await dispatch(setLoaderSpinner(true))
    const response = await dispatch(getOrganizationAccountList(query))
    let newListOne = [{ "value": "", "label": "Select Organization" }]
    let newList = response.data.organization_list.map(org => ({ "value": org.id, "label": `${org.name.charAt(0).toUpperCase() + org.name.slice(1)} (${org.id})` }))
    newListOne = [...newListOne, ...newList]
    setOrganizationList(newListOne)
    await dispatch(setLoaderSpinner(false))
  }
  const fetchCertificateInformation = async () => {
    let params = "?id=" + selectedOrg +
      "&date_range=" + selectedDateRange +
      "&market=" + selectedMarket +
      "&show_accepted_only=" + showAcceptedOnly;

    await dispatch(setLoaderSpinner(true));
    const { status, data } = await dispatch(getCertificateInformation(params));
    setOrgData(data)
    if (!status) {
      history.push("/login");
    }
    await dispatch(setLoaderSpinner(false));
  };

  const fetchCertificateInformationFilter = async () => {
    await dispatch(setLoaderSpinner(true));
    const { status, data } = await dispatch(getCertificateInformationFilter());
    setMarket(data)
    if (!status) {
      history.push("/login");
    }
    await dispatch(setLoaderSpinner(false));
  };

  useEffect(() => {
    window.onbeforeunload = (event) => {
      history.push({
        pathname: "/certificate_information",
        state: undefined
      })
    };
    setSelectedDateRange(history.location.state?.selectedDateRange ?? `${startDate}-${endDate}`)
    setSelectedOrgSelect(history.location.state?.selectedOrgSelect ?? "")
    fetchOrgList()
    fetchCertificateInformationFilter()
  }, []);

  useEffect(() => {
    fetchCertificateInformation();
  }, [selectedOrg, selectedMarket, selectedDateRange, showAcceptedOnly]);

  const handleSelectOrg = (selected) => {
    setSelectedOrgSelect(selected)
    const { value } = selected;
    setSelectedOrg(value)
  }
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      paddingTop: '0px',
      paddingBottom: '0px',
      fontSize: '13px',
      backgroundColor: state.isSelected ? '#2e70d0' : 'white',
      color: state.isSelected ? 'white !important' : '#5B6A83',
      ':hover': {
        backgroundColor: '#2e70d0',
        color: '#fff',
      },
    }),
  };

  const handleCallback = (start, end, label) => {
    const startDateValue = moment(start._d).format("DD/MM/YYYY");
    const endDateValue = moment(end._d).format("DD/MM/YYYY");
    setSelectedDateRange(`${startDateValue}-${endDateValue}`);
  };

  useEffect(() => {
    if (history.location.state !== undefined) {
      fetchCertificateInformation()
    }
  }, [])

  const handleSubmit = () => {
    fetchCertificateInformation(showAcceptedOnly);
    setItemOffset(0);
    history.push({
      pathname: "/certificate_information",
      state: {
        selectedOrg,
        selectedMarket,
        selectedDateRange,
        selectedOrgSelect,
        showAcceptedOnly,
      },
    });
  }

  const handleSwitchChange = () => {
    const newAcceptedOnly = !showAcceptedOnly;
    setShowAcceptedOnly(newAcceptedOnly);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <div className="cert-info-container">
      <div className="lower-header">
        <h3>Certificate information</h3>
      </div>
      <div>
        <div className="cert-info-filters-container">
          <div>
            <label className="cert-info-label">Organization</label>
            <Select
              className="org-id-Dropdown-select"
              styles={customStyles}
              value={selectedOrgSelect}
              options={organizationList}
              onChange={handleSelectOrg}
              placeholder="Select"
            />
          </div>
          <div>
            <label className="cert-info-label">Market</label>
            <Select
              className="org-id-Dropdown-select"
              styles={customStyles}
              value={selectedMarket}
              options={market.map((market) => ({
                key: market.id,
                value: market.id,
                label: capitalizeFirstLetter(market.name)
              }))}
              onChange={(e) => setSelectedMarket(e.value)}
              placeholder={selectedMarketName ? capitalizeFirstLetter(selectedMarketName) : "Select"}
            />
          </div>
          <div className="flex-header">
            <DateRangePicker
              onCallback={handleCallback}
              initialSettings={{ startDate: getStartDate(date), endDate: getEndDate(date), locale: { format: "DD/MM/YYYY" } }}
              onApply={handleSubmit}
            >
              <input
                type="text"
                id="date-range-picker-cert-info"
                className="date-form-box"
              />
            </DateRangePicker>
            <div className="cert-info-icon">
              <FontAwesomeIcon icon={faCalendar} size="1x" color="#e8edfa" />
            </div>
          </div>
          <button className="cert-info-btn" onClick={handleSubmit}>Apply</button>
          <button className="btn btn-primary clear-filter-btn" onClick={clearFilterHandler}>Clear Filter</button>
          <div className="cert-switcher">
            <label className="cert-info-label">Show accepted only</label>
            <CertificateSwitcher showAcceptedOnly={showAcceptedOnly} props={handleSwitchChange} />
          </div>
        </div>
      </div>
      {filteredOrgData && <CertificateInformationTable orgData={filteredOrgData} itemOffset={itemOffset} setItemOffset={setItemOffset} selectedOrg={selectedOrg} selectedDateRange={selectedDateRange} selectedMarket={selectedMarket} selectedOrgSelect={selectedOrgSelect} showAcceptedOnly={showAcceptedOnly} />}
    </div>
  )
}

export default CertificateInformation;