import "./conversion.css";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
// import downloadIcon from "../../assets/images/download_icon.svg";
import RequestLogTable from "./RequestLogTable";
import BanksTable from "./BanksTable";
import AuthLogTable from "./AuthLogTable";
import { useDispatch } from "react-redux";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import {
  getConversionRate,
  getConversionRateFilter,
  getConversionRateXlsx
} from "../../Redux/Actions/ConversionRate";
import { useHistory } from "react-router-dom";
import { ExportXlsx } from "./ExportXlsx";
import DatePicker from "react-datepicker";
import NewFlash from "../FlashMessage/NewFlash";
import ReactSelect from "../ReactSelect/ReactSelect";
import { clearFilterHandler } from "../../Utils/reloadPage";

const Conversion = () => {
  const [totalRequests, setTotalRequests] = useState(0);
  const [acceptedRequests, setAcceptedRequests] = useState(0);
  const [bankData, setBankData] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const [markets, setMarkets] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [certificateTypes, setCertificateTypes] = useState({});
  const [sourceTypes, setSourceTypes] = useState({});
  const [suppliers, setSuppliers] = useState({});
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [selectedMarket, setSelectedMarket] = useState("");
  const [selectedCertificate, setSelectedCertificate] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date());
  let minDate = new Date("January 1 , 2019 23:15:30");
  const [filterYear, setFilterYear] = useState("");
  const [flashMessage, setFlashMessage] = useState(false);
  const [flashData, setFlashData] = useState({ iconImage: "", firstMsg: "", secondMsg: "" })

  useEffect(() => {
    fetchConversion();
    fetchConversionFilter();
  }, []);

  const fetchConversion = async () => {
    const params =
      "?market_id=" + selectedMarket +
      "&customer_id=" + selectedCustomer +
      "&certificate_type=" + selectedCertificate +
      "&source_type=" + selectedSource +
      "&supplier_type=" + selectedSupplier +
      "&year=" + filterYear;


    await dispatch(setLoaderSpinner(true));
    const { status, data } = await dispatch(getConversionRate(params));
    const { total_requests, accepted_requests, bank_data } = data;

    setTotalRequests(total_requests);
    setAcceptedRequests(accepted_requests);
    setBankData(bank_data);

    if (!status) {
      history.push("/login");
    }
    await dispatch(setLoaderSpinner(false));
  };

  const fetchConversionFilter = async () => {
    await dispatch(setLoaderSpinner(true));
    const { data } = await dispatch(getConversionRateFilter());
    const { market, customers, certificate_types, source_types, suppliers } = data;
    setSuppliers(suppliers);
    setMarkets(market);
    setCertificateTypes(certificate_types);
    setSourceTypes(source_types);
    setCustomers(customers);
    await dispatch(setLoaderSpinner(false));
  };

  const generateXlsxFile = async () => {
    const params =
      "?market_id=" + selectedMarket +
      "&customer_id=" + selectedCustomer +
      "&certificate_type=" + selectedCertificate +
      "&source_type=" + selectedSource +
      "&supplier_type=" + selectedSupplier +
      "&year=" + filterYear;

    await dispatch(setLoaderSpinner(true))
    const { status, message } = await dispatch(getConversionRateXlsx(params));
    if (status) {
      await dispatch(setLoaderSpinner(false))
      setFlashMessage(true);
      setFlashData(prevState => ({
        ...prevState,
        iconImage: "tick",
        firstMsg: "Success",
        secondMsg: message
      }))
    }

  }

  let allAccepted = [];
  let noAccepted = [];

  //Find the months of accepted requests that are the same months as total requests and create a new object in array with both accepted requests and total requests
  for (let x = 0; x < totalRequests.length; x++) {
    for (let y = 0; y < acceptedRequests.length; y++) {
      if (totalRequests[x].months === acceptedRequests[y].months) {
        allAccepted.push({
          month: totalRequests[x].months,
          total_requests: totalRequests[x].sum,
          accepted_requests: acceptedRequests[y].sum,
          success_rate:
            ((acceptedRequests[y].sum / totalRequests[x].sum) * 100).toFixed(
              2
            ) + "%",
        });
      }
    }
  }

  //Find the months that doesn't exist in accepted request and create a new object with accepted request sum as 0
  for (let i = 0; i < totalRequests.length; i++) {
    let match = false;
    for (let j = 0; j < acceptedRequests.length; j++) {
      if (totalRequests[i].months === acceptedRequests[j].months) {
        match = true;
        break;
      }
    }
    if (!match) {
      noAccepted.push({
        month: totalRequests[i].months,
        total_requests: totalRequests[i].sum,
        accepted_requests: 0,
        success_rate: ((0 / totalRequests[i].sum) * 100).toFixed(2) + "%",
      });
    }
  }

  const allRequests = allAccepted.concat(noAccepted);

  //sort so newest data is first
  const allRequestsSorted = allRequests.sort((a, b) => {
    return new Date(b.month) - new Date(a.month);
  });

  // Add and calculate percentage to bank data object
  let newBankData = [];
  for (let x = 0; x < bankData.length; x++) {
    for (let y = 0; y < allRequestsSorted.length; y++) {
      if (bankData[x].months === allRequestsSorted[y].month) {
        newBankData.push({
          months: bankData[x].months,
          bank_name: bankData[x].bank_name,
          sum: bankData[x].sum,
          percentage:
            (
              (bankData[x].sum / allRequestsSorted[y].accepted_requests) *
              100
            ).toFixed(2) + "%",
        });
      }
    }
  }

  newBankData.sort((a, b) => {
    return new Date(b.sum) - new Date(a.sum);
  });
  // Add all the data with the same month to one month
  let groupedBankData = newBankData.reduce(function (acc, curr) {
    let findIndex = acc.findIndex(function (item) {
      return item.months === curr.months;
    });
    if (findIndex === -1) {
      let obj = Object.assign(
        {},
        {
          months: curr.months,
          bank_name: [curr.bank_name],
          sum: [curr.sum],
          percentage: [curr.percentage],
        }
      );
      acc.push(obj);
    } else {
      acc[findIndex].bank_name.push(curr.bank_name);
      acc[findIndex].sum.push(curr.sum);
      acc[findIndex].percentage.push(curr.percentage);
    }

    return acc;
  }, []);

  groupedBankData.sort((a, b) => {
    return new Date(b.months) - new Date(a.months);
  });

  const handleSubmit = (event) => {
    fetchConversion();
  };

  const onChangeDate = (date) => {
    setSelectedYear(date);
    let year = date.getFullYear();
    setFilterYear(year);
  };

  const closeFlash = () => {
    setFlashMessage(false);
  }

  useEffect(() => {
    if (filterYear !== "") {
      fetchConversion();
    }
  }, [filterYear]);



  return (
    <div className="conversion-container page-container">
      {flashMessage &&
        <div className={`alert-popup alert-success-bar ${flashMessage ? setTimeout(() => setFlashMessage(false), 3000) & 'alert-shown' : 'alert-hidden'}`}>
          <NewFlash
            data={flashData}
            closeFlash={() => closeFlash()}
          />
        </div>
      }
      <div className="lower-header-conversion">
        <h3>Conversion rate</h3>
        <div className="flex-header-conversion">
          <DatePicker
            selected={selectedYear}
            placeholderText="Choose years"
            showYearPicker
            dateFormat="yyyy"
            value={selectedYear}
            onChange={(date) => onChangeDate(date)}
            className="conversion-daterange"
            maxDate={new Date()}
            minDate={minDate}
            yearItemNumber={8}
          />

          {/* <img src={faCalendar} alt="calendarIcon" /> */}
          <div className="calendar-icon-conversion">
            <FontAwesomeIcon
              icon={faCalendar}
              size="1x"
              color="#e8edfa"
              className="conversion-calendar-icon"
            />
          </div>
          {/* </div> */}

          <div className="download-icon-container">
            <ExportXlsx generateXlsxFile={generateXlsxFile} />
          </div>
        </div>
      </div>
      <div className="filter-container-conversion">
        <div className="conversion-filter-container">
          <label>Supplier</label>
          <select
            className="select-filter-conversion capitalize"
            onChange={(e) => setSelectedSupplier(e.target.value)}
          >
            <option value="">Select</option>
            {suppliers &&
              Object.keys(suppliers).map((supplier, i) => (
                <option value={supplier} key={i}>
                  {supplier.replaceAll("_", " ")}
                </option>
              ))}
          </select>
        </div>
        <div className="conversion-filter-container">
          <label>Market</label>
          <select
            className="select-filter-conversion capitalize"
            onChange={(e) => setSelectedMarket(e.target.value)}
          >
            <option value="">Select</option>
            {markets &&
              markets.map((market, i) => (
                <option value={market[1]} key={i}>
                  {market[1]}
                </option>
              ))}
          </select>
        </div>
        <div className="conversion-filter-container">
          <label>Certificate type</label>
          <select
            className="select-filter-conversion capitalize"
            onChange={(e) => setSelectedCertificate(e.target.value)}
          >
            <option value="">Select</option>
            {certificateTypes &&
              Object.keys(certificateTypes).map((certificate, i) => (
                <option key={i} value={certificate}>
                  {certificate === 'customer' ? 'consumer' : certificate}
                </option>
              ))}
          </select>
        </div>

        <div className="conversion-filter-container">
          <label>Source</label>
          <select
            className="select-filter-conversion capitalize"
            onChange={(e) => setSelectedSource(e.target.value)}
          >
            <option value="">Select</option>
            {sourceTypes &&
              Object.getOwnPropertyNames(sourceTypes).map((val, i) => (
                <option value={val} key={i}>
                  {sourceTypes[val]}
                </option>
              ))}
          </select>
        </div>
        <div className="conversion-filter-container">
          <label>SMS/Email</label>
          <select className="select-filter-conversion capitalize">
            <option value="">Select</option>
            <option key="sms" value="sms">
              SMS
            </option>
            <option key="email" value="email">
              Email
            </option>
          </select>
        </div>
        <div
          className="conversion-filter-container overflow-inherit"
        >
          <label>Customers</label>
          <ReactSelect
            orgList={customers}
            pageRender={"customers"}
            setSelectedCustomer={setSelectedCustomer}
          />
        </div>
        <div className="conversion-filter-container">
          <button className="apply-filter-button" onClick={handleSubmit}>
            Apply
          </button>
        </div>
        <div className="conversion-filter-container">
          <button className="apply-filter-button" onClick={clearFilterHandler}>
            Clear Filters
          </button>
        </div>
      </div>
      <div className="table-wrapper-conversion">
        <h2 className="conversion-table-h2">Request Log</h2>
        {totalRequests && (
          <RequestLogTable allRequestsSorted={allRequestsSorted} />
        )}
      </div>
      <div className="table-wrapper-conversion">
        <h2 className="conversion-table-h2">Banks</h2>
        {bankData && <BanksTable bankData={groupedBankData} />}
      </div>
      <div className="table-wrapper-conversion hide-auth-log">
        <h2 className="conversion-table-h2">Auth Log</h2>
        <AuthLogTable />
      </div>
    </div>
  );
};
export default Conversion;