import "./organizationProviders.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import edit from '../../assets/images/edit.svg';
import ReactPaginate from 'react-paginate';
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';
import CreateOrganizationProvider from '../CreateOrganizationProvider/CreateOrganizationProvider';
import { getOrganizationProvider } from '../../Redux/Actions/OrganizationProvider';
import { OrganizationProviderFilter, deleteOrganizationProvider, editOrganizationProviderIcon, getKreditzProviderList } from '../../Redux/Actions/OrganizationProvider';
import FlashMessage from '../../Components/FlashMessage/FlashMessage';
import remove from "../../assets/images/remove.svg";
import DeleteModal from "../DeleteModal/DeleteModal";
import EditModalBank from "../EditModalBank/editModalBank";
import { setdisplayFlash, getdisplayFlash } from '../../Utils/Common';
import ReactSelect from "../ReactSelect/ReactSelect";
import SearchIcon from '../../assets/images/search_icon.svg'
import { clearFilterHandler } from "../../Utils/reloadPage";


const OrganizationProvider = () => {
  const dispatch = useDispatch();
  let initialData = { "country_id": "", "service_id": "", "organization_id": "", "status": "", "type": "", "banking_authentication_type": "" }
  const history = useHistory();
  const [openEditOrg, setOpenEditOrg] = useState(false);
  const [orgsData, setOrgData] = useState([]);
  const [paginationData, setpaginationData] = useState({ current_page: 1, total_count: '', total_pages: '' })
  const [organizationProvider, setOrganizationProvider] = useState([]);
  const [orgId, setOrgId] = useState([]);
  const [serviceId, setServiceId] = useState([]);
  const [country, setCountry] = useState([]);
  const [filters, setFilters] = useState(initialData);
  const [showPopup, setShowPopup] = useState(false);
  const Bank = useSelector(state => state.organization_management?.bank);
  const textConfirmation1 = ("Are you sure you want")
  const textConfirmation2 = ("to update organization");
  const [openEditBank, setOpenEditBank] = useState(false)
  const [itemForEdit, setItemForEdit] = useState()
  const [editFlashMessage, setEditFlashMessage] = useState(getdisplayFlash());
  const [editFlashData, setEditFlashData] = useState({ iconImage: "", firstMsg: "", secondMsg: "" })
  const [kreditzProviderList, setKreditzProviderList] = useState([])
  const [searchData, setSearchData] = useState("")
  const isFilterActive = filters.organization_id && filters.organization_id !== "" ||
    filters.country_id && filters.country_id !== "" ||
    filters.service_id && filters.service_id !== "" ||
    filters.status && filters.status !== "" ||
    filters.type && filters.type !== "" ||
    filters.banking_authentication_type && filters.banking_authentication_type !== "";


  useEffect(() => {
    fetchOrgList();
    fetchKreditzProvider()
  }, []);

  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setEditFlashMessage(true);
    setEditFlashData(prevState => ({
      ...prevState,
      iconImage: iconImage,
      firstMsg: firstMsg,
      secondMsg: secondMsg
    }))
  }

  const fetchKreditzProvider = async () => {
    const { data } = await dispatch(getKreditzProviderList())
    setKreditzProviderList(data.kreditz_providers)
  }

  const fetchOrgList = async (typeSearch) => {
    let query = ''
    let search = searchData && searchData.length > 0 ? searchData : ""
    query = `?page=&query=${search}`;
    await dispatch(setLoaderSpinner(true))
    const data = await dispatch(getOrganizationProvider(query))
    const { organizations_list, service_list, countries_list, current_page, total_count, total_pages, organization_providers } = data.data
    setOrgId(organizations_list)
    setOrgData(organization_providers)
    setServiceId(service_list);
    setCountry(countries_list);
    setpaginationData(prevState => ({
      ...prevState,
      current_page: current_page,
      total_count: total_count,
      total_pages: total_pages
    }))
    await dispatch(setLoaderSpinner(false))
  }

  const submitHandle = async (page) => {
    const data = filters;
    let query = `?country_id=${data.country_id}&service_id=${data.service_id}&organization_id=${data.organization_id}&status=${data.status}&type=${data.type}&banking_authentication_type=${data.banking_authentication_type}&page=${page}&per_page=50&query=${searchData}`
    const response = await dispatch(OrganizationProviderFilter(query))
    const { current_page, total_count, total_pages, organization_providers } = response.data
    if (response.status) {
      if (response.message === "Unauthorize") {
        history.push("/login")
      } else {
        setOrgData(organization_providers)
        setpaginationData(prevState => ({
          ...prevState,
          current_page: current_page,
          total_count: total_count,
          total_pages: total_pages
        }))
      }
    }
    await dispatch(setLoaderSpinner(false))
  }

  const CreateHandle = (event) => {
    setOpenEditOrg(true)
  };


  const togglePopup = () => {
    setOpenEditOrg(false);
    setOpenEditBank(false)
  }

  const deletePopup = () => {
    setShowPopup(false)
  }

  const handleChange = (type, value) => {
    setFilters(prevState => ({
      ...prevState,
      [type]: value
    }))
  };

  function humanize(str) {
    var i, frags = str?.split('_');
    for (i = 0; i < frags?.length; i++) {
      frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i]?.slice(1);
    }
    return frags?.join(' ');
  }

  const deleteSelected = async () => {
    const { status, message } = await dispatch(deleteOrganizationProvider(organizationProvider.id));
    if (status) {
      deletePopup();
      submitHandle(1)
      openFlash("tick", "Done!", message)
    } else {
      if (message === "Unauthorize") {
        history.push("/login");
      }
    }
  };

  const handleDelete = (organization) => {
    setShowPopup(true);
    setOrganizationProvider(organization);
  };

  const editBankList = async (file_name, status, banking_authentication_type) => {
    const response = await dispatch(editOrganizationProviderIcon(file_name, itemForEdit, status, banking_authentication_type))
    if (response.status && response.message !== 'error') {
      openFlash("tick", "Done!", response.message)
      submitHandle()
    } else {
      openFlash("alert", "Error!", response.message);
    }
    setOpenEditBank(false)
  }

  const editHandler = async (item) => {
    setOpenEditBank(true)
    setItemForEdit(item)
  }

  function closeEditFlash() {
    setdisplayFlash(false);
  }

  const pageChange = (selectedPage) => {
    let newPage = selectedPage.selected + 1;
    submitHandle(newPage);
  }


  return (
    <div className="organization-providers-container page-container" id="organization_providers_page">

      <div className={`alert-popup alert-success-bar ${editFlashMessage ? setTimeout(() => setEditFlashMessage(false), 2000) & setdisplayFlash(false) & 'alert-shown' : 'alert-hidden'}`}>
        <FlashMessage
          iconImage={editFlashData.iconImage}
          firstMsg={editFlashData.firstMsg}
          secondMsg={editFlashData.secondMsg}
          closeFlash={() => closeEditFlash()}
        />
      </div>
      {
        openEditOrg ?
          <CreateOrganizationProvider
            closeDelete={togglePopup}
            id={organizationProvider.id}
            kreditzProviderList={kreditzProviderList}
            openFlash={openFlash}
            humanize={humanize}
            submitHandle={submitHandle}
            orgId={orgId}
            serviceId={serviceId}
            country={country}
          />
          : null
      }
      {
        openEditBank && <EditModalBank
          firstTextUser={textConfirmation1}
          secondTextUser={textConfirmation2}
          Bank={Bank}
          itemForEdit={itemForEdit}
          confirm={editBankList}
          closeDelete={togglePopup}
          openFlash={openFlash}
          setOpenEditBank={setOpenEditBank}
        />
      }
      {
        showPopup ?
          <DeleteModal
            firstTextUser={"Are you sure you want"}
            secondTextUser={"to delete"}
            Id={organizationProvider.id}
            organizationProvider={organizationProvider}
            confirm={deleteSelected}
            closeDelete={deletePopup}
          />
          : null
      }
      <div className="lower-header-organization-providers">
        <h3>Organization Providers</h3>
        <div className="select-search tab-date ms-auto">
          <div className="search-input org-search-input me-3">
            <input
              className="search-input search-kreditz-provider"
              name="search"
              value={searchData}
              placeholder="Search by Identifier"
              onChange={(e) => setSearchData(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  submitHandle();
                }
              }}
            />
            <img className="search-icon" src={SearchIcon} alt="SearchIcon" onClick={() => submitHandle()} />
          </div>
        </div>
        <button className="service-button" onClick={() => CreateHandle()}>Create Organization Provider</button>
      </div>

      <div className="filter-container-orgprovider">
        <div className="organization-provider-filter-container overflow-inherit">
          <label>Organization</label>
          <ReactSelect
            orgList={orgId}
            handleChange={handleChange}
          />
        </div>
        <div className="organization-provider-filter-container">
          <label>Service</label>
          <select className="select-filter-organization-provider capitalize" onChange={(e) => handleChange("service_id", e.target.value)}>
            <option value="">Select</option>
            {
              serviceId && serviceId.map((service, index) => (
                <option value={service.id}
                  key={`serviceId-option-${index + 1}`}>
                  {humanize(service.name)}
                </option>
              ))
            }
          </select>
        </div>
        <div className="organization-provider-filter-container">
          <label>Country</label>
          <select className="select-filter-organization-provider capitalize" onChange={(e) => handleChange("country_id", e.target.value)}>
            <option value="">Select</option>
            {
              country && country.map((_country, index) => (
                <option value={_country.id}
                  key={`country-option-${index + 1}`}>
                  {_country.name}
                </option>
              ))
            }
          </select>
        </div>
        <div className="organization-provider-filter-container">
          <label>Certificate Type</label>
          <select className="select-filter-organization-provider" onChange={(e) => handleChange("type", e.target.value)}>
            <option value="">Select</option>
            <option value="private">Consumer</option>
            <option value="business">Corporate</option>
          </select>
        </div>
        <div className="organization-provider-filter-container">
          <label>Active</label>
          <select className="select-filter-organization-provider" onChange={(e) => handleChange("status", e.target.value)}>
            <option value="">Select</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>
        <div className="organization-provider-filter-container">
          <label>Authentication Type</label>
          <select className="select-filter-organization-provider" onChange={(e) => handleChange("banking_authentication_type", e.target.value)}>
            <option value="">Select</option>
            <option value="decoupled">Decoupled</option>
            <option value="redirect">Redirect</option>
          </select>
        </div>
        <div className="organization-provider-filter-container">
          <button className={isFilterActive ? "apply-filter-button" : "apply-filter-button login_submit_disabled"} onClick={() => {
            submitHandle(1)
          }}>
            Filter
          </button>
        </div>
        <div className="organization-provider-filter-container">
          <button className={isFilterActive ? "apply-filter-button" : "apply-filter-button login_submit_disabled"} onClick={() => {
            clearFilterHandler()
          }}>
            Clear Filters
          </button>
        </div>
      </div >
      <div className="bg-white-organizations bg-20-top" style={{ margin: "20px 0 0 0" }}>
        <div className="organizations-thead-scroll-y">
          <table className="table" width="100%">
            <thead className="organizations-providers-thead">
              <tr>
                <td className="org-head">Organization</td>
                <td className="org-head">Service</td>
                <td className="org-head width-td-100">Country</td>
                <td className="org-head width-td-100">Type</td>
                <td className="org-head width-td-100">Status</td>
                <td className="org-head">Identifier</td>
                <td className="org-head">Icon</td>
                <td className="org-head">Authentication Type</td>
              </tr>
            </thead>
            <tbody className="organizations-tbody">
              {
                orgsData && orgsData.length > 0 && orgsData.map((item) => {
                  return (
                    <tr key={`tr-op-${item.id + 1} `} id={`op-${item.id}`}>
                      <td className="org-td">{item.organization_name} ({`${item.organization_id}`})</td>
                      <td className="org-td">{humanize(item.service_name)}</td>
                      <td className="org-td">{item.country_name.charAt(0).toUpperCase() + item.country_name.slice(1)}</td>
                      <td className="org-td">{item.service_provider_type.charAt(0).toUpperCase() + item.service_provider_type.slice(1)}</td>
                      <td className="org-td">{JSON.stringify(item?.op_status)?.charAt(0).toUpperCase() + JSON.stringify(item?.op_status)?.slice(1)}</td>
                      <td className="org-td">{item.service_provider_identifier}</td>
                      <td className="org-td">
                        <a href={item.op_icon} target="_blank" rel="noreferrer" className="email-img-cover op_img"><img style={{ display: item.op_icon ? "block" : "none" }} src={item.op_icon} alt="view" width="40" /></a>
                      </td>
                      <td className="org-td capitalize">
                        {item?.banking_authentication_type ? item?.banking_authentication_type : 'Null'}
                      </td>
                      <td className="org-td org-action">
                        <center>
                          <a name="organization_provider_handler" href="#organization_provider_handler">
                            <img className="email-img" src={edit} alt="edit" width="20" onClick={(e) => editHandler(item)} />
                          </a>
                          <a name="organization_provider_handler" href="#organization_provider_handler">
                            <img className="email-img" src={remove} alt="edit" width="20" onClick={(e) => handleDelete(item)} />
                          </a>
                        </center >
                      </td >
                    </tr >
                  )
                })
              }
            </tbody >
          </table >
        </div >
        <div className="pagination-box">
          {paginationData.total_count > 50 &&
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              breakLabel={"..."}
              pageCount={Math.ceil(paginationData.total_pages)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={pageChange}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={paginationData.current_page - 1}
            />
          }
        </div>
      </div >
    </div >
  );
};

export default OrganizationProvider;