import React from 'react';
import { clearFilterHandler } from '../../../Utils/reloadPage';

const LiveDataFilters = ({
  certificate_type,
  via,
  mode,
  status,
  organizationId,
  countryId,
  connectionType,
  suppliers,
  apiVersion,
  handleSelectedEnvironment,
  handleSelectedRequestType,
  handleSelectedMode,
  handleSelectedVia,
  handleSelectedStatus,
  handleSelectedOrganizationId,
  handleSelectedCountryId,
  handleSelectedProviderId,
  handleSelectedConnectionType,
  handleSelectedApiVersion,
  selectedCountryId,
}) => {

  const capitalizeFirstLetter = (string) => {
    return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const parsedCountryId = parseInt(selectedCountryId, 10);

  const filteredConnections = React.useMemo(() => connectionType
    .filter(connection => connection.country_id === parsedCountryId)
    .sort((a, b) => a.display_name.localeCompare(b.display_name)), [connectionType, parsedCountryId]);


  function formatString(str) {
    return str
      ?.replace(/_/g, ' ')
      ?.toLowerCase()
      ?.replace(/^\w/, (c) => c?.toUpperCase());
  }

  function renderApiVersionOptions(apiVersion) {
    return apiVersion
      .filter(key => !key.includes('?'))
      .filter((key, index, self) => self.indexOf(key) === index)
      .sort((a, b) => a.localeCompare(b))
      .map((key) => (
        <option key={key} value={key}>Version {key}</option>
      ));
  }

  return (
    <div className="filter-container-customers">
      <div className="customers-filter-container">
        <label>Environment</label>
        <select name="enviroment" className="select-filter-customers" onChange={handleSelectedEnvironment}>
          <option value="" key="all">All</option>
          <option value="live" key="live">Live</option>
          <option value="sandbox" key="sandbox">Sandbox</option>
        </select>
      </div>
      <div className="customers-filter-container">
        <label>Type</label>
        <select name="type" className="select-filter-type" onChange={handleSelectedRequestType}>
          <option value="">All</option>
          {
            Object.entries(certificate_type).map(([key, value], index) => (
              <option key={index} value={key}>{capitalizeFirstLetter(value)}</option>
            ))
          }
        </select>
      </div>
      <div className="customers-filter-container">
        <label>Mode</label>
        <select name="mode" className="select-filter-type" onChange={handleSelectedMode}>
          <option value="" key="all">All</option>
          {
            Object.entries(mode).map(([key, value], index) => (
              <option key={index} value={key}>{formatString(value)}</option>
            ))
          }
        </select>
      </div>
      <div className="customers-filter-container">
        <label>Via</label>
        <select name="via" className="select-filter-type" onChange={handleSelectedVia}>
          <option value="" key="all">All</option>
          {
            Object.entries(via).map(([key, value]) => {
              if (value) {
                return (
                  <option key={key} value={key}>{formatString(value)}</option>
                );
              }
              return null;
            })
          }
        </select>
      </div>
      <div className="customers-filter-container">
        <label>Status</label>
        <select name="status" className="select-filter-type" onChange={handleSelectedStatus}>
          <option value="" key="all">All</option>
          {
            Object.entries(status).map(([key, value]) => (
              <option key={key} value={key}>{formatString(value)}</option>
            ))
          }
        </select>
      </div>
      <div className="customers-filter-container">
        <label>Organization id</label>
        <select name="organizationId" className="select-filter-type" onChange={handleSelectedOrganizationId}>
          <option value="" key="all">All</option>
          {
            organizationId.length > 0 ? organizationId.map((org) => (
              <option key={org[0]} value={org[0]}>{org[1]}</option>

            )) : null
          }
        </select>
      </div>
      <div className="customers-filter-container">
        <label>Country id</label>
        <select name="country" className="select-filter-type" onChange={handleSelectedCountryId}>
          <option value="" key="all">All</option>
          {
            countryId.length > 0 ? countryId.map((key) => (
              <option key={key.id} value={key.id}>{capitalizeFirstLetter(key.name)}</option>
            )) : null
          }
        </select>
      </div>
      <div className="customers-filter-container">
        <label>Provider id</label>
        <select name="provider" className="select-filter-type" onChange={handleSelectedProviderId}>
          <option value="" key="all">All</option>
          {
            filteredConnections.length > 0 ? filteredConnections.map((connection) => (
              <option key={connection.provider_id} value={connection.provider_id}>{connection.display_name}</option>
            )) : connectionType.map((key) => (
              <option key={key.provider_id} value={key.provider_id}>{key.display_name}</option>))
          }
        </select>
      </div>
      <div className="customers-filter-container">
        <label>Connection type</label>
        <select name="connections" className="select-filter-type" onChange={handleSelectedConnectionType}>
          <option value="" key="all">All</option>
          {suppliers.length > 0 ? suppliers.map((item) => (
            <option key={item[0]} value={item[0]}>{formatString(item[1])}</option>
          )) : null}
        </select>
      </div>
      <div className="customers-filter-container">
        <label>Api version</label>
        <select name="apiVersion" className="select-filter-type" onChange={handleSelectedApiVersion}>
          <option value="" key="all">All</option>
          {apiVersion.length > 0 ? renderApiVersionOptions(apiVersion) : null}
        </select>
      </div>
      <div className="service-settings-filter-container">
        <button className="btn btn-primary clear-filter-btn" onClick={() => clearFilterHandler()}>
          Clear Filters
        </button>
      </div>
    </div >
  );
}

export default LiveDataFilters;