import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
const AcceptedRequestsGraph = (props) => {

  let currentYearRequests = [];
  Object.values(props.currentYearRequests).map((x) => {
    return currentYearRequests.push(x);
  });

  let previousYearRequests = [];
  Object.values(props.previousYearRequests).map((x) => {
    return previousYearRequests.push(x);
  });

  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  if (props.slicedNumber > 0) {
    months = months.slice(0, props.slicedNumber)
    previousYearRequests = previousYearRequests.slice(0, props.slicedNumber)
    currentYearRequests = currentYearRequests.slice(0, props.slicedNumber)
  }
  const options = {
    chart: {
      type: props.graphType,
      spacing: [15, 0, 15, 15],
      style: {
        fontFamily: "'Noto Sans', sans-serif",
        color: "#8591A6",
      },
    },
    title: {
      text: "",
      margin: 40,
    },

    legend: {
      itemStyle: {
        fontFamily: "SF Pro Display",
        color: "#596980",
        fontWeight: "regular",
      },
      symbolRadius: 0
    },
    tooltip: {
      animation: false,
      backgroundColor: "#ffffff",
      style: {
        color: "#333333",
      },
    },
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        pointWidth: 15,
        groupPadding: 0.25,
      },
      line: {
        dashStyle: "LongDash",
      },
      series: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold"
          },
          allowOverlap: true,
          crop: false,
          overflow: 'allow',
          formatter: function () {
            return Highcharts.numberFormat(this.y, 0, ' ');
          },
        },
        minPointLength: 2,
        groupPadding: 0.4,
        pointPadding: 0.2,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    colors: ["#3e85f9", "#43BF58"],
    xAxis: {
      categories: months,
      lineWidth: 0,
      labels: {
        style: {
          color: "#8591A6",
        },
      },
    },
    yAxis: {
      min: 0,
      offset: -10,
      title: {
        text: "",
        x: -20,
        style: {
          color: "#8591A6",
        },
      },
      labels: {
        style: {
          color: "#8591A6",
          formatter: function () {
            return Highcharts.numberFormat(this.value, 0, ' ');
          },
        },
      },
      gridLineColor: "#CFD7E5",
      gridLineDashStyle: "longDash",
      gridLineWidth: 0.5,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: props.previousYear,
        data: previousYearRequests,
        dataLabels: {
          x: -10,
        },
        borderRadiusTopLeft: "10px",
        borderRadiusTopRight: "10px",
        tooltip: {
          headerFormat:
            "<span>{point.key} " + props.previousYear + "<br></br></span>",
          pointFormat:
            '<span class="highcharts-tooltip-live-data">{point.y}</span>',
        },
        categories: "test",
        marker: {
          symbol: "square",
        }
      },
      {
        name: props.currentYear,
        data: currentYearRequests,
        dataLabels: {
          x: 10,
        },
        borderRadiusTopLeft: "10px",
        borderRadiusTopRight: "10px",
        tooltip: {
          headerFormat:
            "<span>{point.key} " + props.currentYear + "<br></br></span>",
          pointFormat:
            '<span class="highcharts-tooltip-live-data">{point.y}</span>',
        },
        marker: {
          symbol: "square",
        }
      },
    ],
  };
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}

export default AcceptedRequestsGraph;