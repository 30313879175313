import { useState, useEffect } from "react";
import { getOrganizationListNoActivity } from "../../Redux/Actions/OrganizationManagement";
import { useDispatch } from "react-redux";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import moment from "moment";
import excelIcon from '../../assets/images/excel_icon.png'
import "./OrgNoActivity.css"
import * as XLSX from "xlsx";

const OrgNoActivity = () => {
  const [noActivityOrg, setNoActivityOrg] = useState([])
  const dispatch = useDispatch()


  const noActivityHandler = async () => {
    await dispatch(setLoaderSpinner(true))
    const { data } = await dispatch(getOrganizationListNoActivity())
    setNoActivityOrg(data)
    await dispatch(setLoaderSpinner(false))
  }

  useEffect(() => {
    noActivityHandler()
  }, [])

  const capitalizeName = (key) => {
    switch (key) {
      case 'main_account':
        return 'Main account'
      case 'sub_account':
        return 'Sub account'
      default:
        return key;
    }
  }

  const generateExcel = () => {
    const leftAlignFormat = { numFmt: "@", alignment: { horizontal: "left", indent: 1 } }

    const excelData = noActivityOrg.map((item) => ({
      'ID': item.id ? { v: item.id, t: 's', s: leftAlignFormat } : null,
      'Client Name': item.name || null,
      'Main/Sub account': capitalizeName(item.account_type) || null,
      'Main account': item.parent_name || null,
      'Over 3 months since last': item.last_created_at ? moment(item.last_created_at).format("YYYY-MM-DD") : null
    }));

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    worksheet['!cols'] = [{ wch: 10, style: { alignment: { float: 'left' } } }, { wch: 30 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
    worksheet['!rows'] = [{ hpt: 30 }];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Organizations_No_activity");
    XLSX.writeFile(workbook, "Organizations_No_activity.xlsx");
  }

  return (
    <div className="kreditz-provider-container page-container" id="kreditz_provider_page">
      <div className="lower-header-kreditz-provider">
        <h3 style={{ margin: "0px 0 20px 0" }}>Organizations-No activity</h3>
      </div>
      <div className="organizations-thead-scroll-y org-no-activity">
        <div className="bg-white-organizations bg-20-top">
          <table className="table" width="100%">
            <thead className="organizations-thead">
              <tr>
                <td className="org-head org-no-activity org-no-activity-id">ID activity</td>
                <td className="org-head">Client Name</td>
                <td className="org-head">Main/Sub account</td>
                <td className="org-head">Main account</td>
                <td className="org-head">
                  <div className="org-head-excel-container">
                    Over 3 months since last
                    <span className='org-no-activity-excel-icon' onClick={generateExcel}>
                      <img className='org-no-activity-excel-icon' src={excelIcon} alt="excel icon" />
                    </span>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody className="organizations-tbody">
              {
                noActivityOrg && noActivityOrg.length > 0 && noActivityOrg.map((item) => {
                  return (
                    <tr key={`kreditz-providers-${item.id + 1} `}
                      id={`${item.id}`}>
                      <td className="org-td org-no-activity">{item.id === null ? '-' : item.id}</td>
                      <td className="org-td">{item.name === null ? '-' : item.name}</td>
                      <td className="org-td">{item.account_type === null ? '-' : capitalizeName(item.account_type)}</td>
                      <td className="org-td">{item.parent_name === null ? '-' : item.parent_name}</td>
                      <td className="org-td">{item.last_created_at === null ? '-' : moment(item.last_created_at).format("DD-MM-YYYY")}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrgNoActivity;